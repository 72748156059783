@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmer-rank li:last-child {
  border: none;
}

.no-wef {
  margin-left: 0 !important;
}

.my-shimmer-effect-container .shimmer {
  background: #2e2e2e;
  background-image: linear-gradient(to right,
      rgba(46, 46, 46, 0.8) 0%,
      rgba(60, 60, 60, 0.8) 20%,
      rgba(46, 46, 46, 0.8) 40%,
      rgba(46, 46, 46, 0.8) 100%);
  background-repeat: no-repeat;
  background-size: 2000px 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.my-shimmer-effect-container {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0;
}

.blur-area {
  width: 100%;
  height: auto;
}

.blur-comp {}

.MuiInputBase-root {
  width: 100%;
  position: relative;
  height: auto;
}

.css-qiwgdb {
  height: 100%;
  width: 100%;
  position: relative;
}

.shimmer-wrapper {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 32px;
}

.shimmer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 24px;
}

.shimmer-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 12px 16px;
  overflow: hidden;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(0, 0, 0, 0.15) 100%);
}

.shimmer-title {
  height: 14px;
  width: 50%;
  margin-bottom: 16px;
  border-radius: 4px;
}

.shimmer-text {
  height: 20px;
  width: 67%;
  border-radius: 4px;
}

.shimmer-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
}

.shimmer-button {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 4px 4px #1e1e1e;
  background-color: rgba(60, 60, 60, 0.9);
}

.shimmer {
  display: inline-block;
  background: #2e2e2e;
  background-image: linear-gradient(to right,
      rgba(46, 46, 46, 0.8) 0%,
      rgba(60, 60, 60, 0.8) 20%,
      rgba(46, 46, 46, 0.8) 40%,
      rgba(46, 46, 46, 0.8) 100%);
  background-repeat: no-repeat;
  background-size: 2000px 100%;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  z-index: 1;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  overflow: hidden;
}