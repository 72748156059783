.nova-box .styles_winNovachest__6MiKg {
  margin-bottom: 100px;
  margin-top: 50px;
}

.nova-box {
  margin-bottom: 100px;
  position: relative;
  z-index: 1; /* Ensures content is above the background */
}

.nova-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../../assets/img/nova-chest-banner.png) center center /
    cover no-repeat;
  opacity: 0.35;
  z-index: -1;
}

@media (min-width: 1024px) {
  .nova-section-header {
    padding-top: 42.5px;
  }
}

.nova-highlight {
  background-color: rgba(252, 132, 98, 0.2);
  border-radius: 6px;
  padding: 3px 9px 3px 16px;
  margin-inline: 12px;
}

@media (min-width: 650px) {
  .nova-box .styles_winNovachest__6MiKg {
    margin-top: 70px !important;
    margin-bottom: 0px !important;
  }
}

.point-holders {
  height: 30px;
  width: 25px;
  display: inline-block;
  transform: translate(-7.5px, -3px);
}

@media (min-width: 1024px) {
  .nova-box .gap-20 {
    gap: 2.5rem;
  }
}

.styles_Novachest__6MiKg {
  overflow: hidden;
}
.hover\:text-\[30px\]:hover {
  font-size: 30px !important;
}
.nova-box .text-\[71px\] {
  font-size: 71px;
}
.nova-box .text-center {
  text-align: center;
}
.nova-box .mb-0 {
  margin-bottom: 0;
}

.nova-box .font-medium {
  font-weight: 500;
}
.nova-box .text-\[28px\] {
  font-size: 28px;
}
.nova-box .text-center {
  text-align: center;
}
.nova-box .mt-\[20px\] {
  margin-top: 20px;
}
.nova-box .mb-0 {
  margin-bottom: 0;
}
.nova-box p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 130%;
}
.nova-box .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.nova-box .font-medium {
  font-weight: 500;
}
.nova-box .text-\[28px\] {
  font-size: 28px;
}
.nova-box .justify-center {
  justify-content: center;
}
.nova-box .items-center {
  align-items: center;
}
.nova-box .w-\[960px\] {
  width: 850px;
}
.nova-box .h-auto {
  height: auto;
}
.nova-box .mr-\[-150px\] {
  margin-right: -150px;
}

.nova-chests {
  height: 550px;
  width: 550px;
}

.nova-box .ml-\[-250px\] {
  margin-left: -0px;
}
.nova-box .px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}
.nova-box .gap-4 {
  gap: 1rem;
}
.nova-box 

/*  */

.font-semibold {
  font-weight: 600;
}
.nova-box .text-\[40px\] {
  font-size: 30px;
}
.nova-box .w-\[44px\] {
  width: 44px;
}
.nova-box .h-\[47px\] {
  height: 47px;
}
.nova-box .relative {
  position: relative;
}
.nova-box 
/*  */
.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
}
.translate-x-\[-50\%\],
.translate-y-\[-50\%\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.nova-box .translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
}
.nova-box .top-\[50\%\] {
  top: 50%;
}
.nova-box .left-\[50\%\] {
  left: 50%;
}
.nova-box .absolute {
  position: absolute;
}
.nova-box 
/*  */
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.nova-box .leading-\[24px\] {
  line-height: 24px;
}
.nova-box .font-bold {
  font-weight: 700;
}
.nova-box .text-\[24px\] {
  font-size: 24px;
}
.nova-box 
/*  */

.font-semibold {
  font-weight: 600;
}

.nova-box 
/*  */

.backdrop-blur-\[5px\] {
  --tw-backdrop-blur: blur(5px);
}
.nova-box .backdrop-blur-\[4px\],
.nova-box .backdrop-blur-\[5px\] {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
.nova-box .p-\[30px\] {
  padding: 30px;
}
.nova-box .bg-\[\#0910191A\] {
  background-color: #0910191a;
}
.nova-box .rounded-\[18px\] {
  border-radius: 18px;
}
.nova-box .gap-\[7px\] {
  gap: 7px;
}
.nova-box .justify-center {
  justify-content: center;
}
.nova-box .items-center {
  align-items: center;
}
.nova-box .w-max {
  width: -moz-max-content;
  width: max-content;
}
.nova-box .mr-\[-32px\] {
  margin-right: -32px;
}

.nova-box .ml-\[-32px\] {
  margin-left: -32px;
}
/*  */
.nova-box .text-accent {
  --tw-text-opacity: 1;
  color: #fc6432;
}
.nova-box .text-\[44px\] {
  font-size: 44px;
}
.nova-box .text-center {
  text-align: center;
}
.nova-box .w-\[60px\] {
  width: 60px;
}
.nova-box 
/*  */

.text-\[20px\] {
  font-size: 20px;
}
/*  */
.nova-box .rotate-180,
.nova-box .scale-0 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.nova-box .rotate-180 {
  --tw-rotate: 180deg;
}
.nova-box .text-\[30px\] {
  font-size: 30px;
}
.nova-box 
/*  */
p {
  font-family: "Matter Mono", sans-serif;
}
/*  */

.nova-box .mx-\[100px\] {
  margin-left: 100px;
  margin-right: 100px;
}
.nova-box .jad svg {
  margin-right: 4px;
}
.nova-box .mb-10 {
  margin-bottom: 2rem;
}
/*  */
@media (max-width: 1149px) {
  .nova-box .xl\:flex-wrap {
    flex-wrap: wrap;
  }
}

@media (min-width: 767px) {
  .nova-box p {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .nova-box .md\:mx-\[30px\] {
    margin-left: 30px;
    margin-right: 30px;
  }
  .nova-box .mt-\[20px\] {
    height: 40px !important;
  }
  .nova-box .gap-20 {
    gap: 0 !important;
  }
}
@media (max-width: 560px) {
  .nova-box .sm\:text-\[18px\] {
    font-size: 18px;
  }
  .nova-box .sm\:text-\[24px\] {
    font-size: 18px;
  }
}
/*  */
@media (max-width: 560px) {
  .nova-box .sm\:min-w-\[600px\] {
    min-width: 600px;
  }
}

@media (max-width: 560px) {
  .nova-box .sm\:h-\[90px\] {
    height: 90px;
  }
}
@media (max-width: 560px) {
  .nova-box .sm\:text-\[30px\] {
    font-size: 30px;
  }
}
@media (max-width: 560px) {
  .nova-box .sm\:text-\[14px\] {
    font-size: 14px;
  }
}
@media (max-width: 1149px) {
  .nova-box .xl\:ml-0 {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .nova-box .lg\:min-w-\[1000px\] {
    min-width: 1000px;
  }
}

@media (max-width: 560px) {
  .nova-box .sm\:scale-\[80\%\] {
    --tw-scale-x: 80%;
    --tw-scale-y: 80%;
  }
  .nova-box .sm\:scale-90,
  .nova-box .sm\:scale-\[80\%\] {
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0)
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
@media (max-width: 560px) {
  .nova-box .sm\:p-0 {
    padding: 0;
  }
}
@media (max-width: 560px) {
  .nova-box .sm\:w-\[40px\] {
    width: 37px;
  }
}

@media (max-width: 560px) {
  .nova-box .sm\:h-\[90px\] {
    height: 90px;
  }
}

@media (max-width: 560px) {
  .nova-box .sm\:gap-2 {
    gap: 0.5rem;
  }
}
@media (max-width: 560px) {
  .nova-box .sm\:mt-\[15px\] {
    margin-top: 15px;
  }
}

@media (max-width: 560px) {
  .nova-box .sm\:h-\[90px\] {
    height: 90px;
  }
}
@media (max-width: 600px) {
  .nova-box .PartnerSection_PartnerSection__QX3xC {
    margin-block: 50px 30px;
  }
}
/*  */
@media (max-width: 1149px) {
  .nova-box .xl\:ml-0 {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .nova-box .lg\:min-w-\[1000px\] {
    min-width: 1000px;
  }
}
@media (max-width: 767px) {
  .nova-box .md\:mr-\[-350px\] {
    margin-right: 0;
    /* width: 100% !important; */
    min-width: 100% !important;
    margin-left: 0px;
  }
}
/*  */
.nova-box .gap-10 {
  gap: 2.5rem;
}
.nova-box .items-end {
  align-items: flex-end;
}
.nova-box .flex-col {
  flex-direction: column;
}
.nova-box .max-w-\[1200px\] {
  max-width: 1200px;
}
.nova-box .flex {
  display: flex;
}
.nova-box .m-auto {
  margin: auto;
}
.nova-box .mb-\[-50px\] {
  margin-bottom: -50px;
}
.nova-box .select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.nova-box .max-w-\[500px\] {
  max-width: 500px;
}
.nova-box .pointer-events-none {
  pointer-events: none;
}
.nova-box .select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.nova-box .max-w-\[500px\] {
  max-width: 500px;
}
.nova-box .pointer-events-none {
  pointer-events: none;
}
.nova-box .justify-center {
  justify-content: center;
}
.nova-box .items-end {
  align-items: flex-end;
}
.nova-box .max-h-\[410px\] {
  max-height: 410px;
}
.nova-box .hidden {
  display: none;
}
.nova-box .select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.nova-box .max-w-\[700px\] {
  max-width: 700px;
}
.nova-box .gap-\[10px\] {
  gap: 10px;
}
.nova-box .justify-center {
  justify-content: center;
}
.nova-box .items-center {
  align-items: center;
}
.nova-box .flex {
  display: flex;
}
.nova-box .mt-\[20px\] {
  margin-top: 20px;
}
.nova-box .w-\[29px\] {
  width: 25px;
}
.nova-box .h-\[29px\] {
  height: 25px;
}
.nova-box .text-\[12px\] {
  font-size: 12px;
}
.nova-box .items-center {
  align-items: center;
}
.nova-box .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.nova-box .text-gray {
  --tw-text-opacity: 1;
  /* color: rgb(111 111 127 / var(--tw-text-opacity)); */
  color: rgba(255, 255, 255, 0.7);
}
.nova-box .text-\[16px\] {
  font-size: 16px;
}
.nova-box .text-center {
  text-align: center;
}
.nova-box .hidden {
  display: none;
}
.nova-box .mb-5 {
  margin-bottom: 1.25rem;
}
.nova-box .gap-\[3px\] {
  gap: 3px;
}
.nova-box .justify-center {
  justify-content: center;
}
.nova-box .flex-wrap {
  flex-wrap: wrap;
}
.nova-box .max-w-\[612px\] {
  max-width: 612px;
}
.nova-box .styles_card__FV9HB {
  background: linear-gradient(
    135deg,
    transparent 0 5px,
    #1a1a1a 5px calc(100% - 5px),
    transparent calc(100% - 5px) 100%
  );
}

@media (min-width: 768px) {
  .nova-box .tbMin\:h-\[200px\] {
    height: 200px;
  }
}
.nova-box .py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}
.nova-box .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.nova-box .justify-center {
  justify-content: center;
}
.nova-box .items-center {
  align-items: center;
}
.nova-box .flex-col {
  flex-direction: column;
}
.nova-box .flex-grow-\[3\] {
  flex-grow: 3;
}
.nova-box .max-w-\[200px\] {
  max-width: 200px;
}
.nova-box .w-\[30\%\] {
  width: 30%;
}
.nova-box .top-0 {
  top: 0;
}
.nova-box .left-0,
.nova-box .left-\[0px\] {
  left: 0;
}
.nova-box .absolute {
  position: absolute;
}
.nova-box .max-w-\[123px\] {
  max-width: 123px;
}
.nova-box .w-full {
  width: 100%;
}
.nova-box .max-h-\[123px\] {
  max-height: 123px;
}
.nova-box .h-auto {
  height: auto;
}
.nova-box .block {
  display: block;
}
.nova-box .z-\[1\] {
  z-index: 1;
}
.nova-box .text-\[12px\] {
  font-size: 12px;
}
.nova-box .text-center {
  text-align: center;
}
.nova-box .mt-\[2px\] {
  margin-top: 2px;
}
.nova-box .z-\[1\] {
  z-index: 1;
}
.nova-box .pxs {
  margin-top: 30px;
}
@media (max-width: 997px) {
  .nova-box .max-w-\[612px\] {
    max-width: none;
    margin-top: 28px;
  }
}
@media (max-width: 700px) {
  .nova-box .max-w-\[612px\] {
    margin-top: 0px;
  }
}

@media (min-width: 768px) {
  .nova-box .tbMin\:text-\[16px\] {
    font-size: 16px;
  }

  .nova-box .mt78 {
    margin-inline: 0 !important;
    /* margin-top: 70px; */
  }
}
@media (min-width: 768px) {
  .nova-box .tbMin\:gap-\[6px\] {
    gap: 6px;
  }
}

@media (min-width: 768px) {
  .nova-box .tbMin\:text-\[16px\] {
    font-size: 16px;
  }

  .nova-box .pxs {
    margin-top: 0;
  }
}
@media (min-width: 998px) {
  .nova-box .lpMin\:block {
    display: block;
  }
}

@media (min-width: 998px) {
  .nova-box .lpMin\:flex {
    display: flex;
  }
}
@media (min-width: 998px) {
  .nova-box .lpMin\:hidden {
    display: none;
  }
}

@media (min-width: 998px) {
  .nova-box .lpMin\:justify-between {
    justify-content: space-between;
  }

  .nova-box .lpMin\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 998px) {
  .nova-box .lpMin\:overflow-visible {
    overflow: visible;
  }
}

@media (min-width: 998px) {
  .nova-box .lpMin\:hidden {
    display: none;
  }
}
@media (min-width: 998px) {
  .nova-box .lpMin\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 998px) {
  .nova-box .lpMin\:max-w-\[420px\] {
    /* max-width: 420px; */
    max-width: 470px;
  }
}
@media (min-width: 998px) {
  .nova-box .lpMin\:flex {
    display: flex;
  }
}
