.AppContainer_AppContainer__zMOpX {
  width: 100%;
  padding-right: 18px;
  padding-left: 18px;
}

.aws-logo {
  height: 50px;
}

.okx-wallet {
  height: 50px;
}

.leather-wallet {
  height: 40px;
}

.home_HomePage__uPApL
  .home_articleListSection__JvNO_
  .home_sectionHeader__suL52 {
  text-align: center;
  margin-bottom: 48px;
}
.h050 {
  height: 50px !important;
}
.maer {
  margin-left: 5px !important;
  cursor: pointer;
}
.don-ing {
  width: 20px !important;
}

.home_HomePage__uPApL .home_sectionHeader__suL52 {
  margin-bottom: 24px;
}
.SectionHeaderV2_SectionHeaderV2__UELE4 {
  display: flex;
  flex-direction: column;
}
.PartnerSection_PartnerSection__QX3xC {
  margin-block: 100px;
}

.SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ {
  font-size: 30px;
  font-weight: 700;
  font-family: "Matter SQ", sans-serif !important;
  color: #fff;
}
.SectionHeaderV2_SectionHeaderV2__UELE4
  .SectionHeaderV2_sectionHeaderDescription___9fqh {
  margin-top: 12px;
  font-size: 16px;
  opacity: 0.6;
  color: #fff;
}

.home_HomePage__uPApL
  .home_articleListSection__JvNO_
  .home_articleListGrid__7oGz6 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  column-gap: 24px;
  grid-row-gap: 24px;
  row-gap: 24px;
}
.ArticleListCard_MediaListCard__T98sa {
  transition: all 0.3s ease !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1c1d23;
}
.ArticleListCard_MediaListCard__T98sa a {
  display: block;
  width: 100%;
  display: grid;
}
.ArticleListCard_MediaListCard__T98sa
  a
  .ArticleListCard_coverImageContainer__MOJ43 {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  transition: all 0.25s ease-in;
  display: block;
  overflow: hidden;
}

.ArticleListCard_MediaListCard__T98sa
  a
  .ArticleListCard_coverImageContainer__MOJ43
  .ArticleListCard_coverImage__5_cum {
  background-color: #121212;
  display: block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 150px;
}
.ArticleListCard_MediaListCard__T98sa
  a
  .ArticleListCard_informationContainer__XHHO9 {
  width: 100%;
  padding: 24px;
  overflow: hidden;
  position: relative;
}
.ArticleListCard_MediaListCard__T98sa
  a
  .ArticleListCard_informationContainer__XHHO9
  .ArticleListCard_title__0m_rS {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}
.ArticleListCard_MediaListCard__T98sa
  a
  .ArticleListCard_informationContainer__XHHO9
  .ArticleListCard_subtitle__MskMM {
  opacity: 0.7;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.asked {
  top: 0;
  bottom: 0;
  margin: auto;
}

.AppContainer_AppContainer__zMOpX {
  width: 100%;
  padding-right: 18px;
  padding-left: 18px;
}
.PartnerSection_PartnerSection__QX3xC .PartnerSection_sectionHeader__QYsEV {
  text-align: center;
  margin-bottom: 24px;
}

.SectionHeader_SectionHeader__mzGAY {
  display: flex;
  flex-direction: column;
}
.SectionHeader_SectionHeader__mzGAY
  .SectionHeader_sectionHeaderDescription__lsO4g {
  margin-top: 12px;
  font-size: 16px;
  opacity: 0.6;
  color: #fff;
}

.PartnerSection_PartnerSection__QX3xC .PartnerSection_partnerItemGrid__QAC1L {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 24px;
  row-gap: 24px;
}

.PartnerSection_PartnerSection__QX3xC
  .PartnerSection_partnerItemGrid__QAC1L
  .PartnerSection_partnerItem__pc1jj {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PartnerSection_PartnerSection__QX3xC
  .PartnerSection_partnerItemGrid__QAC1L
  .PartnerSection_partnerItem__pc1jj
  img {
  opacity: 0.7;
  transition: all 0.25s ease-in;
  width: 80%;
}

.PartnerSection_PartnerSection__QX3xC
  .PartnerSection_partnerItemGrid__QAC1L
  .PartnerSection_partnerItem__pc1jj
  img:hover {
  opacity: 1;
  transition: all 0.25s ease-in;
}

@media only screen and (min-width: 768px) {
  .PartnerSection_PartnerSection__QX3xC
    .PartnerSection_partnerItemGrid__QAC1L
    .PartnerSection_partnerItem__pc1jj
    img {
    width: 100%;
  }

  .PartnerSection_PartnerSection__QX3xC
    .PartnerSection_partnerItemGrid__QAC1L
    .PartnerSection_partnerItem__pc1jj {
    padding: 0 48px;
  }
  .PartnerSection_PartnerSection__QX3xC .PartnerSection_partnerItemGrid__QAC1L {
    grid-template-columns: repeat(4, 1fr);
  }
  .AppContainer_AppContainer__zMOpX {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  .ArticleListCard_MediaListCard__T98sa
    a
    .ArticleListCard_coverImageContainer__MOJ43
    .ArticleListCard_coverImage__5_cum {
    height: 150px;
  }

  .home_HomePage__uPApL
    .home_articleListSection__JvNO_
    .home_articleListGrid__7oGz6 {
    grid-template-columns: repeat(3, 1fr);
  }

  .SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ {
    font-size: 48px;
  }
  .AppContainer_AppContainer__zMOpX {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  .home_HomePage__uPApL .home_sectionHeader__suL52 {
    margin-bottom: 48px;
  }
  .ArticleListCard_MediaListCard__T98sa:hover {
    transform: translateY(-5px);
    transition: all 0.3s ease !important;
    background-color: #272932;
  }
}
@media (max-width: 1350px) {
  .asked {
    display: none;
  }
}
/*  */

.marquee-horizontal-large {
  z-index: 200;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}
.track-horizontal {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-horizontal 30s linear infinite;
}
.icon-container-alt a {
  opacity: 1;
  transition: all 0.3s ease;
}
.icon-container-alt .logos__link:hover img {
  opacity: 1;
  transition: all 0.3s ease;
}
.rfm-marquee-container {
  min-height: 140px;
}
@keyframes marquee-horizontal {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.track-horizontal {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.flex-horizontal {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}
.icon-container-alt {
  border-style: none;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 100px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.logotype {
  opacity: 0.7;
  cursor: pointer;
  mix-blend-mode: hard-light;
  flex: 0 auto;
  width: 100%;
  transition: opacity 0.2s;
}

.spacer-20._15-xsvp {
  flex: none;
  width: 20px;
}
.spacer-20 {
  width: 100%;
  height: 30px;
}

@media screen and (max-width: 479px) {
  .spacer-20._15-xsvp {
    height: 15px;
  }

  .icon-container-alt {
    width: 200px;
    height: 100px;
  }
  .marquee-horizontal-large {
    height: 130px;
  }
}
