.mattersq {
    font-family: "Matter SQ", sans-serif;
    font-weight: 700 !important;
}

.btn-buy-now:hover {
    transform: scale(1.03);
}

.max-w-1000 {
    max-width: 1150px;
    margin: auto;
    padding-inline: 20px;
}

.main-buy .list-disc {
    padding-inline-start: 40px !important;
    margin-block: 1rem !important
}

.main-buy {
    padding-top: 95px;
    margin-bottom: 30px;
    overflow-x: hidden;
    overflow-y: hidden
}

.text-transparent {
    color: transparent;
}

.leading-\[110\%\] {
    line-height: 110%;
}

.capitalize {
    text-transform: capitalize;
}

.font-medium {
    font-weight: 500;
}

.color-orange {
    color: #fc6432
}

.text-\[34px\] {
    font-size: 38px;
}

.text-center {
    text-align: center;
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
}

.bg-gradient-title {
    color: white;
}

.mb-\[32px\] {
    margin-bottom: 32px;
}


.gap-\[32px\] {
    gap: 32px;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.flex {
    display: flex;
}

.z-20 {
    z-index: 20;
}

.relative {
    position: relative;
}

.gap-\[24px\] {
    gap: 24px;
}

.justify-center {
    justify-content: center;
}

.flex-col {
    flex-direction: column;
}

.gap-\[12px\] {
    gap: 12px;
}

.justify-start {
    justify-content: flex-start;
}

.p-\[16px\] {
    padding: 16px;
}

.bg-cover {
    background-size: cover;
}

.need-help {
    height: 28px;
    display: inline-block;
    margin-right: 10px;
    transform: translateY(-2.25px);
}

.bg-roadmapBg {
    background: linear-gradient(135deg, #1c1c1c, #fc643233);
    border: 1px solid #ffffff1a;
}

.text-\[\#fc6432\] {
    color: #fc6432;
}

.bg-roadmapBg:hover {
    background: linear-gradient(135deg, #2a2a2a, #fc643250);
    border: 1px solid #fc643250;
}

.rounded-\[10px\] {
    border-radius: 10px;
}

.gap-\[12px\] {
    gap: 12px;
}

.snapshot-container {
    position: relative;
    width: 1110px;
    height: 625px;
    margin: 0 auto;
    background: linear-gradient(135deg, #1c1c1c, #fc643233);
    border: 1px solid #ffffff1a;
    border-radius: 10px;
    /* Slightly rounded corners */
    overflow: hidden;
}

.snapshot-container::before,
.snapshot-container::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #fc6432;
}

.snapshot-container::before {
    top: 0;
    left: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}

.snapshot-container::after {
    bottom: 0;
    right: 0;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
}

.snapshot-image {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.snapshot-image:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 25px rgba(252, 100, 50, 0.5);
}

.text-transparent {
    color: transparent;
}

.leading-\[110\%\] {
    line-height: 110%;
}

.capitalize {
    text-transform: capitalize;
}

.font-medium {
    font-weight: 500;
}

.text-\[20px\] {
    font-size: 20px;
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
}

.bg-\[\#0A0914\],
.bg-\[\#0a0914\] {
    --tw-bg-opacity: 1;
    background-color: rgb(10 9 20 / var(--tw-bg-opacity));
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);
}

.rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);
}

.translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);
}

.w-12 {
    width: 3rem;
}

.h-12 {
    height: 3rem;
}

.block {
    display: block;
}

.right-0 {
    right: 0;
}

.bottom-\[-50px\] {
    bottom: -50px;
}


.text-\[rgba\(250\,_250\,_250\,_0\.64\)\] {
    color: #EAEAEA;
}

.leading-\[150\%\] {
    line-height: 150%;
}

.font-medium {
    font-weight: 500;
}

.text-\[16px\] {
    font-size: 16px;
}

.text-\[\#FAFAFA\] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}

.h-\[400px\] {
    height: 400px;
}

.fix-btn {
    position: fixed;
    width: 300px;
    right: 0px;
    left: 0;
    bottom: 1rem;
    margin: auto;
    z-index: 9999
}

.mbl-snap {
    display: none;
}

@media (min-width: 992px) {
    .laptop\:text-\[20px\] {
        font-size: 20px;
    }

    .fix-btn {
        width: 450px;

    }

    .laptop\:gap-\[40px\] {
        gap: 40px;
    }

    .laptop\:gap-\[18px\] {
        gap: 18px;
    }

    .laptop\:text-\[26px\] {
        font-size: 28px;
    }

    .main-buy {
        margin-bottom: 80px
    }
}

@media (min-width: 992px) {
    .laptop\:text-\[64px\] {
        font-size: 70px;
    }
}

@media (min-width: 992px) {
    .laptop\:mb-\[60px\] {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .mainbuy .laptop\:hidden {
        display: none;
    }
}

@media (max-width:768px) {
    .desk-snap {
        display: none;
    }

    .snapshot-container {
        height: auto;
        width: 100%;
    }

    .snapshot-container::before,
    .snapshot-container::after {
        display: none;
    }

    .mbl-snap {
        display: block;
    }

}