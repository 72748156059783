.section-2024 {
  background-color: #0a0a0a;
  padding-bottom: 100px;
  margin-top: 100px;
}

.section-2024 .container-medium-thesis {
  max-width: 1218px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.section-2024 .w-container {
  max-width: 1218px;

  margin-left: auto;
  margin-right: auto;
}

.inner-container {
  height: auto;
  max-width: 100%;
  grid-column-gap: 16px;
  /* grid-row-gap: 16px; */
  flex-direction: column;
  grid-template-rows: 60px;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.section-2024 .h2 {
  z-index: 999;
  color: var(--elements-webflow-library-neutral--300);
  text-align: center;
  letter-spacing: -1px;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#505050 19%, #161616);
  -webkit-background-clip: text;
  background-clip: text;

  font-size: 5.5rem;
  font-weight: 500;
  line-height: 1.4;
  position: relative;
  font-family: "Matter SQ", sans-serif !important;
}
/*  */
.section-2024 h2 {
  color: #eff0f6;
  margin-top: 20px;
  margin-bottom: 10px;

  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}
.section-2024 .paragraph.white {
  width: auto;
  color: var(--flowui-component-library-grey-300);
  text-align: center;
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 1;
}
.section-2024 .paragraph {
  width: auto;
  color: rgba(242, 241, 255, 0.7);
  letter-spacing: -0.3px;
  margin-bottom: 30px;

  font-size: 16px;
  line-height: 28px;
}
.section-2024 .w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.flex-cards {
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 1250px;
  margin: auto;
  gap: 20px;
  margin-top: 50px;
}
.area-heading {
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 40px;
}
/* .single-card:hover {
  background-size: 105%, auto;
  transition: all 0.3s ease !important;
}
.single-card:hover {
  transition: all 0.3s ease !important;
  box-shadow: 0 0 12px 3px rgba(252, 100, 50, 0.75);
} */
:where(.ReactFlipCard__flipCard),
:where(.ReactFlipCard__rotateY180Deg) {
  transition: all 0.5s ease-in !important;
}
.ext-btn {
  top: 1%;
  width: 36px;
  height: 36px;
  background-color: black;
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 40% 40%;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  top: 2%;
  bottom: auto;
  left: auto;
  right: 2%;
}
.focus-modal-contents {
  height: 100%;
  object-fit: fill;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  display: flex;
  overflow: auto;
}
/* .ReactFlipCard__flipCardBack .single-card:hover {
  background-size: 105%, auto;
  transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 0 12px 3px rgba(151, 71, 255, 0.75);
} */
.ReactFlipCard__flipCardBack .content-card {
  margin: 0 !important;
}

.ecosystem-features {
  color: rgba(255, 255, 255, 0.7);
}

.paragraph-focus-card {
  margin-top: 0;
  margin-bottom: 5px;
  /* color: #ffffff; */
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  font-weight: 300;
  overflow: hidden;
  line-height: 15px;
}
@media screen and (max-width: 991px) {
  .paragraph-focus-card {
    font-size: 12px;
    line-height: 16px;
  }
}
.h4-card-back {
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}
.areas-image {
  width: 160px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-inline: auto;
}
.single-card {
  transition: all 0.5s ease !important;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  border: 1px solid #3b3b3b;
  -webkit-text-stroke-width: 0px;
  border-radius: 20px;
  justify-content: center;
  cursor: pointer;
  padding: 20px 25px 25px;
  min-height: 525px;
  align-items: start;
  flex-direction: column;
  background: linear-gradient(167.22deg, #fc6432 -2.81%, #121314 31.73%);
  display: flex;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.3);
  position: relative;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; /* Safari */
}
.content-card {
  color: #f2f1ff;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 170px;
  font-size: 18px;
  line-height: 30px;
  display: flex;
}
.btn-main-card {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  width: 100%;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

.vectors-wrapper {
  width: 100%;
  height: 48px;
  object-fit: cover;
  background-color: rgba(84, 84, 84, 0.4);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.button-text {
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 9px;
  left: 20px;
}
.plus-image {
  margin-top: 2px;
  position: absolute;
  top: 10px;
  height: 24px;
  left: auto;
  right: 30px;
}
.abs-img {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -9;
  right: 0;
}
.abs-img img {
  width: 100%;
}
:where(.ReactFlipCard__container) {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 1024px) {
  .flex-cards {
    flex-wrap: wrap;
  }
  .single-card {
    height: 100%;
  }
  :where(.ReactFlipCard__container) {
    width: 48% !important;
  }
  :where(.ReactFlipCard__container) {
    margin-bottom: 2 px;
  }
}
@media (max-width: 554px) {
  :where(.ReactFlipCard__container) {
    width: 100% !important;
  }
  .flex-cards {
    flex-wrap: wrap;
  }
  /* .single-card {
    width: 98%;
  } */
}

.w-\[400px\] {
  width: 500px;
}
.top-\[-200px\] {
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}
.left-\[-180px\] {
  left: -180px;
}
.absolute {
  position: absolute;
}
@media (min-width: 768px) {
  .md\:w-\[500px\] {
    width: 500px;
  }
  .md\:top-\[-250px\] {
    top: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
  }
  .md\:left-\[-150px\] {
    left: -150px;
  }
}
@media (min-width: 1024px) {
  .lg\:w-\[700px\] {
    width: 500px;
  }
  .lg\:top-\[-200px\] {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .lg\:left-\[-100px\] {
    left: -100px;
  }
}
@media (min-width: 1440px) {
  .xl\:w-\[833px\] {
    width: 550px;
  }
  .xl\:top-\[-250px\] {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .xl\:left-\[-0px\] {
    left: -0px;
  }
}

@media (max-width: 600px) {
  .section-2024 {
    margin-top: 30px;

    margin-bottom: 30px;
  }
  .mtless.mt-96 {
    margin-top: 60px !important;
  }
}
