/* shimmer.css */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  display: inline-block;
  background: #2e2e2e;
  background-image: linear-gradient(
    to right,
    #2e2e2e 0%,
    #3c3c3c 20%,
    #2e2e2e 40%,
    #2e2e2e 100%
  );
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  border-radius: 4px;
}

.shimmer-text {
  height: 16px;
  border-radius: 4px;
}

/* .shimmer-text {
  height: 16px;
  border-radius: 4px;
} */

.shimmer-icon-ref {
  width: 24px;
  height: 24px;
  border-radius: 35%;
  margin-left: 10px;
}

.shimmer-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 10px;
}
