@keyframes shimmerr {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmerr {
  display: inline-block;
  position: relative;
  z-index: 10;
  background: #2e2e2e;
  background-image: linear-gradient(
    to right,
    #2e2e2e 0%,
    #3c3c3c 20%,
    #2e2e2e 40%,
    #2e2e2e 100%
  );
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  border-radius: 4px;
}

.shimmerr-text {
  width: 100px;
  height: 16px;
  /* margin-bottom: 8px; */
}

.shimmerr-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
}

.shimmerr-title {
  width: 150px;
  height: 18px;
  margin-bottom: 8px;
}

.shimmerr-large-text {
  width: 200px;
  height: 18px;
  margin-bottom: 8px;
}

.shimmerr-progress {
  width: 100%;
  height: 10px;
  border-radius: 4px;
  /* margin-top: 10px; */
  position: relative;
  z-index: 10;
}

.shimmerr-progress-coin {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  z-index: 15;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}
