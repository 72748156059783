.RoadmapSection_RoadmapSection__jVegv .RoadmapSection_sectionHeader__9fYuR {
  text-align: center;
  margin-bottom: 48px;
}

.swx {
  padding-block: 150px;
}

.SectionHeaderV2_SectionHeaderV2__UELE4 {
  display: flex;
  flex-direction: column;
}
.SectionHeaderV2_SectionHeaderV2__UELE4
  .SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.SectionHeaderV2_SectionHeaderV2__UELE4
  .SectionHeaderV2_sectionHeaderDescription___9fqh {
  margin-top: 12px;
  font-size: 16px;
  opacity: 0.6;
  color: #fff;
}

.RoadmapSection_RoadmapSection__jVegv .RoadmapSection_roadmapItemGrid__UEPtU {
  display: grid;
  grid-auto-flow: column;
  padding-top: 0.75;
  padding-bottom: 2px;
  padding: 0 0.25rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smoothx;
  width: 100%;
  position: relative;
  column-gap: 1rem;
  /* grid-template-columns: 1fr; */
  /* grid-template-columns: repeat(4, 1fr); */
  grid-auto-columns: calc(26%);
  grid-column-gap: 24px;
  column-gap: 24px;
  grid-row-gap: 24px;
  row-gap: 24px;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0 {
  padding: 0 12px;
  min-height: 200px;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemHeader__1_gyO {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  column-gap: 12px;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemHeader__1_gyO
  .RoadmapSection_year__kPhPy {
  /* background-color: hsla(0, 0%, 100%, 0.2); */
  background-color: rgba(252, 100, 50, 0.55);
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 24px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemHeader__1_gyO
  .RoadmapSection_timeline__0qVRw {
  height: 2px;
  width: 100%;
  /* background: linear-gradient(270deg, #ab66ff, #14f195 101.39%); */
  background: linear-gradient(270deg, #ff5512, #fc6432, #141414);
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemContent__mAhFQ
  .RoadmapSection_itemTitle__wxXIz {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemContent__mAhFQ
  .RoadmapSection_itemList__jZGEe {
  display: grid;
  grid-row-gap: 12px;
  row-gap: 12px;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemContent__mAhFQ
  .RoadmapSection_itemList__jZGEe
  .RoadmapSection_milestone__uMKMP {
  color: rgba(255, 255, 255, 0.7);
  background-color: #1a1a1a;
  padding: 12px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemContent__mAhFQ
  .RoadmapSection_itemList__jZGEe
  .RoadmapSection_milestone__uMKMP
  .RoadmapSection_icon__yqw_C,
.RoadmapSection_RoadmapSection__jVegv
  .RoadmapSection_roadmapItemGrid__UEPtU
  .RoadmapSection_roadmapItem__x63r0
  .RoadmapSection_itemContent__mAhFQ
  .RoadmapSection_itemList__jZGEe
  .RoadmapSection_milestone__uMKMP
  .RoadmapSection_icon__yqw_C
  img {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}
@media only screen and (min-width: 768px) {
  .RoadmapSection_RoadmapSection__jVegv .RoadmapSection_roadmapItemGrid__UEPtU {
    column-gap: 24px;
    /* grid-template-columns: repeat(4, 1fr); */
  }
  .SectionHeaderV2_SectionHeaderV2__UELE4
    .SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ {
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  .RoadmapSection_RoadmapSection__jVegv .RoadmapSection_roadmapItemGrid__UEPtU {
    grid-auto-columns: calc(41.67%) !important;
  }
  .swx {
    padding-block: 100px;
  }
}
@media (max-width: 510px) {
  .RoadmapSection_RoadmapSection__jVegv .RoadmapSection_roadmapItemGrid__UEPtU {
    grid-auto-columns: calc(66.67%) !important;
  }
}
