.tokenomics .zD {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-column-gap: 30px;
  column-gap: 30px;
  align-items: center;
  /* padding-top: 100px; */
  padding-top: 65px;
}

.total-supply-heading {
  font-size: 26px;
}

@media (max-width: 767px) {
  .total-supply-heading {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .token-distribution-heading {
    display: block;
  }
}

@media (max-width: 1200px) {
  .token-distribution-section {
    text-align: center;
  }

  .max-12 {
    display: none;
  }
}

.tokenomics {
  padding-bottom: 100px;
}

.tokenomics .h2,
.tokenomics h2 {
  /* margin-bottom: 30px; */

  line-height: normal;
  font-family: "Matter SQ", sans-serif !important;
  font-weight: 500;
}

.ms-2 {
  margin-left: 5px;
}

.tokenomics .zE {
  text-wrap: pretty;
  max-width: 380px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.7);
}

.tokenomics h2 {
  font-size: 48px;
  font-weight: bold;
}

.tokenomics .btn {
  display: inline-flex;
  background-color: #fc6432 !important;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.tokenomics .btn {
  --bs-btn-padding-x: 20px;
  --bs-btn-padding-y: 12px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.2;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 24px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 1;
  --bs-btn-focus-box-shadow: 0 0 0 0.2em rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  transition-behavior: normal, normal, normal, normal;
  transition-duration: 0.2s, 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background-color, border-color, box-shadow;
}

.tokenomics .btn svg {
  font-size: 24px;
  display: block;
}

.tokenomics .btn {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.tokenomics .zF {
  justify-self: end;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.tokenomics .zG {
  position: absolute;
  bottom: -410px;
  left: -570px;
  z-index: -1;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  filter: saturate(1.1);
  transform: rotate(194deg) translateZ(0);
}

/*  */
@media (max-width: 1199.98px) {
  .tokenomics .zG {
    bottom: -260px;
    left: -740px;
    max-width: 1350.8333333333px;
  }

  .tokenomics .zD {
    grid-template-columns: auto;
    padding-top: 80px;
    row-gap: 60px;
  }

  .tokenomics .zE {
    max-width: 100%;
  }

  .zF,
  .zF img {
    width: 100%;
  }
}

@media (max-width: 1023.98px) {
  .tokenomics .zG {
    bottom: 400px;
    left: -540px;
    max-width: 1000px;
  }

  .tokenomics .zD {
    row-gap: 60px;
    padding-top: 70px;
  }
}

@media (max-width: 767.98px) {
  .tokenomics .zG {
    bottom: 250px;
    left: -380px;
    max-width: 700px;
  }

  .tokenomics .btn {
    display: flex;
    width: 100%;
    max-width: 385px;
    margin: auto;
  }

  .tokenomics .h2,
  .tokenomics h2 {
    font-size: 28px;
  }

  .tokenomics .zD {
    row-gap: 40px;
    padding-top: 00px;
  }
}

@media (max-width: 400px) {
  .tokenomics .zG {
    display: none;
  }
}