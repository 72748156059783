/* Global Scrollbar Styling for WebKit Browsers (including Safari) */
::-webkit-scrollbar {
  width: 12px; /* Set width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #1e1e1e; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px; /* Rounded corners for thumb */
  /* Avoid using border or margin inside scrollbar for Safari */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change thumb color on hover */
}

/* Fallback styling for Firefox */
* {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #888 #1e1e1e; /* Sets thumb and track colors */
}

html {
  scrollbar-gutter: stable; /* Reserve space for scrollbar */
}
