.referral-network-summary {
  background-color: rgb(28, 28, 28);
  border-radius: 20px;
  padding: 16px;
}

.referral-network-summary h2 {
  font-family: "Matter SQ", sans-serif;
  font-size: 24px;
  color: #fc6432;
  margin-bottom: 8px;
}

.referral-network-summary hr {
  border: none;
  border-top: 1px solid rgba(128, 128, 128, 0.3);
  margin: 8px 0 16px;
}

.referral-network-summary .empty-state {
  text-align: center;
  color: #fff;
}

.referral-network-summary .empty-state img {
  height: auto;
  margin-bottom: 12px;
}

.referral-network-summary .empty-state h3 {
  margin-bottom: 8px;
  font-size: 18px;
  font-family: "Matter SQ", sans-serif;
  color: #ffffff;
}

.referral-network-summary .empty-state p {
  margin-bottom: 12px;
  font-size: 14px;
}

.referral-network-summary .empty-state .copy-link-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: rgba(17, 15, 22, 0.42);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.referral .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.font-weight-bold {
  font-weight: bold;
}

.main-tp.ab-to {
  width: max-content;
  left: 44%;
  bottom: 24px;
  font-size: 14px !important;
}

._copyBtn_1fjrj_201 {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-top: -3px;
}

.bbtm {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  color: rgb(252, 100, 50);
}

.top-dup-faq {
  margin-top: 45px !important;
}

.rank-num {
  width: 150px;
}

.text-simplre {
  font-family: "Matter SQ", sans-serif;
  font-size: 24px !important;
}

.referral-heading {
  transform: translateY(7.5px);
}

.referral .laptop\:mt-\[32px\] {
  padding: 27px;
}

.referral .pb-\[48px\] {
  padding-bottom: 48px;
}

.referral .gap-\[48px\] {
  gap: 48px;
}

.referral .w-\[20px\] {
  width: 20px;
}

.referral .gap-\[16px\] {
  gap: 16px;
}

.referral .h-\[20px\] {
  height: 20px;
}

.referral .mb-\[12px\] {
  margin-bottom: 12px;
}

.referral .tabIndex {
  width: max-content;
}

.referral .text-right {
  text-align: right;
}

.referral .py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.referral .px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.referral .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.referral .selectedli {
  background-color: #333333 !important;
}

.referral .grid {
  display: grid;
}

.referral .mt-\[20px\] {
  margin-top: 20px;
}

.referral .text-\[\#D6D6D6\] {
  --tw-text-opacity: 1;
  color: rgb(214 214 214 / var(--tw-text-opacity));
}

.referral .leading-\[150\%\] {
  line-height: 150%;
}

.referral .text-\[14px\] {
  font-size: 14px;
  font-weight: bold;
}

.text-14px {
  font-size: 14px;
}

.referral .mb-\[20px\] {
  margin-bottom: 20px;
}

.referral .top-\[-10px\] {
  top: -10px;
}

.referral .right-\[0\] {
  right: 0;
}

.referral .leading-\[120\%\] {
  line-height: 120%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[32px\] {
  font-size: 32px;
}

/*  */
.referral .py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.referral .px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.referral .overflow-hidden {
  overflow: hidden;
}

.referral .flex-col {
  flex-direction: column;
}

.referral .gap-\[8px\] {
  gap: 8px;
}

.referral .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.referral .grid {
  display: grid;
}

.referral .mb-\[12px\] {
  margin-bottom: 12px;
}

.referral .mt-\[20px\] {
  margin-top: 20px;
}

.referral .justify-between {
  justify-content: space-between;
}

/*  */
.referral .text-\[\#9AA4B2\] {
  --tw-text-opacity: 1;
  color: rgb(154 164 178 / var(--tw-text-opacity));
}

.referral .leading-\[145\%\] {
  line-height: 145%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[16px\] {
  font-size: 16px;
}

.referral
/*  */

.gap-\[8px\] {
  gap: 8px;
}

.referral .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.referral .grid {
  display: grid;
}

.referral .mb-\[20px\] {
  margin-bottom: 20px;
}

.referral

/*  */
.gap-\[8px\] {
  gap: 8px;
}

.referral
/*  */

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.referral .px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.referral .overflow-hidden {
  overflow: hidden;
}

.referral
/*  */

.gap-\[8px\] {
  gap: 8px;
}

.referral .flex-col {
  flex-direction: column;
}

.referral .flex {
  display: flex;
}

.referral .mb-\[20px\] {
  margin-bottom: 20px;
}

.referral .text-\[\#9AA4B2\] {
  --tw-text-opacity: 1;
  color: rgb(154 164 178 / var(--tw-text-opacity));
}

.referral .leading-\[145\%\] {
  line-height: 145%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[16px\] {
  font-size: 16px;
}

.referral .mt-\[8px\] {
  margin-top: 8px;
}

.referral

/*  */
.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.referral .px-\[17px\] {
  padding-left: 17px;
  padding-right: 17px;
}

.referral .flex-\[0_1_50\%\] {
  flex: 0 1 50%;
}

.referral .items-center {
  align-items: center;
}

.referral .inline-flex {
  display: inline-flex;
}

.referral .mb-\[4px\] {
  margin-bottom: 4px;
}

.referral .text-\[\#79716B\] {
  --tw-text-opacity: 1;
  color: rgb(121 113 107 / var(--tw-text-opacity));
}

.referral .leading-\[170\%\] {
  line-height: 170%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[14px\] {
  font-size: 14px;
}

.referral .mr-\[6px\] {
  margin-right: 6px;
}

.referral .border-\[\#110f1652\] {
  border-color: #110f1652;
}

.referral .border-solid {
  border-style: solid;
}

.referral .border {
  border-width: 1px;
}

.referral .w-\[1px\] {
  width: 1px;
}

.referral .h-\[75\%\] {
  height: 75%;
}

.referral .top-\[12px\] {
  top: 12px;
}

.referral .right-\[0\] {
  right: 0;
}

.referral .bottom-\[12px\] {
  bottom: 12px;
}

.referral .p-\[4px\] {
  padding: 4px;
}

.referral .gap-\[4px\] {
  gap: 4px;
}

.referral .flex-\[1_0_40\%\] {
  flex: 1 0 40%;
}

.referral .text-\[\#79716B\] {
  --tw-text-opacity: 1;
  color: rgb(121 113 107 / var(--tw-text-opacity));
}

.referral .leading-\[170\%\] {
  line-height: 170%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[14px\] {
  font-size: 14px;
}

.referral .leading-\[140\%\] {
  line-height: 140%;
}

.referral .css-xa1gac {
  font-family: "Matter Mono", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
}

.referral .stswap-select {
  background: #110f166b;
  outline: none;
  border-radius: 0 !important;
}

.referral .css-qiwgdb.css-qiwgdb.css-qiwgdb {
  padding-right: 32px;
}

.referral .css-qiwgdb.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.referral .stswap-select .MuiSelect-select {
  border-radius: 0;
  color: #79716b;
  font-family: "Matter Mono", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.referral .css-qiwgdb {
  appearance: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.referral .stswap-select .MuiSelect-select em {
  font-style: normal;
}

.referral .css-1k3x8v3 {
  bottom: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.referral .stswap-select .MuiSvgIcon-root {
  fill: #fc6432;
}

.referral .css-1636szt {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - 0.5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.referral .stswap-select .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 0 !important;
}

.referral .css-ihdtdm {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0px;
  line-height: 11px;
  transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.referral .css-igs3ac {
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.referral .grid {
  display: grid;
}

.referral .mt-\[12px\] {
  margin-top: 12px;
}

.referral ._base_rhd5v_1:disabled._orange_rhd5v_17 {
  /* background: #6d410e; */
  background: #fc6432;
}

.referral ._base_rhd5v_1:disabled {
  pointer-events: none;
}

.referral .text-typographyBlack {
  color: #110f16;
}

.referral .font-medium {
  font-weight: 500;
}

.referral .text-\[16px\] {
  font-size: 16px;
}

.referral .py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.referral .px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.referral .bg-defaultOrange {
  background-color: #fc6432;
  height: 47px;
  border: 1px solid rgba(246, 220, 202, 0.4);
  border-radius: 0.5rem;
  opacity: 0.6;
}

.referral .whitespace-break-spaces {
  white-space: break-spaces;
}

.referral .justify-center {
  justify-content: center;
}

.referral .items-center {
  align-items: center;
}

.referral .flex {
  display: flex;
}

.referral .mt-\[20px\] {
  margin-top: 20px;
}

.referral :disabled {
  cursor: default;
}

.referral ._base_rhd5v_1 {
  box-shadow: 6px 6px #00000052;
}

.referral ._base_rhd5v_1:disabled._orange_rhd5v_17 ._decorLeft_rhd5v_4,
.referral ._base_rhd5v_1:disabled._orange_rhd5v_17 ._decorRight_rhd5v_5,
.referral ._base_rhd5v_1:disabled._orange_rhd5v_17 ._text_rhd5v_20 {
  /* color: #fc6432; */
  color: white;
  font-weight: bold;
}

.referral .leading-\[160\%\] {
  line-height: 160%;
}

.referral .text-\[18px\] {
  font-size: 18px;
}

.referral ._base_rhd5v_1 ._decorLeft_rhd5v_4,
.referral ._base_rhd5v_1 ._decorRight_rhd5v_5 {
  transition: transform 0.3s ease;
}

.referral .text-\[\#110F16\] {
  --tw-text-opacity: 1;
  color: rgb(17 15 22 / var(--tw-text-opacity));
}

.referral .text-\[16px\] {
  font-size: 16px;
}

.referral .mx-\[12px\] {
  margin-left: 12px;
  margin-right: 12px;
}

.referral .py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.referral .px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.referral .border-\[\#57534E\] {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.referral .border-solid {
  border-style: solid;
}

.referral .gap-\[20px\] {
  gap: 20px;
}

.referral .text-\[\#CDD5DF\] {
  --tw-text-opacity: 1;
  color: rgb(205 213 223 / var(--tw-text-opacity));
}

.referral .leading-\[120\%\] {
  line-height: 120%;
}

.referral .uppercase {
  text-transform: uppercase;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[14px\] {
  font-size: 14px;
}

.referral .gap-\[8px\] {
  gap: 8px;
}

.referral .items-center {
  align-items: center;
}

.referral .leading-\[120\%\] {
  line-height: 120%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[32px\] {
  font-size: 32px;
}

.referral .leading-\[120\%\] {
  line-height: 120%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[28px\] {
  font-size: 28px;
}

.referral .mt-\[28px\] {
  margin-top: 28px;
}

.referral .mb-\[16px\] {
  margin-bottom: 16px;
}

.referral .bg-\[\#171412\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 22 26/ 0.7);
}

.referral .border-\[\#57534E\] {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.referral .p-\[20px\] {
  padding: 20px;
}

.referral .border-\[\#57534E\] {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.referral .border-solid {
  border-style: solid;
}

.referral .border-b {
  border-bottom-width: 1px;
}

.referral .gap-\[24px\] {
  gap: 24px;
}

.referral .items-center {
  align-items: center;
}

.referral .flex-wrap {
  flex-wrap: wrap;
}

.referral .text-\[\#D6D6D6\] {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, 0.7);
}

.referral .leading-\[120\%\] {
  line-height: 120%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[18px\] {
  font-size: 18px;
}

.referral .flex-\[0_0_34px\] {
  flex: 0 0 34px;
}

.referral .gap-\[8px\] {
  gap: 8px;
}

.referral .rounded-full {
  border-radius: 9999px;
}

.referral .overflow-hidden {
  overflow: hidden;
}

.referral .w-\[32px\] {
  width: 32px;
}

.referral .h-\[32px\] {
  height: 32px;
}

.referral .text-\[\#A9A29D\] {
  --tw-text-opacity: 1;
  color: rgb(169 162 157 / var(--tw-text-opacity));
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[16px\] {
  font-size: 16px;
}

.referral .text-\[\#A9A29D\] {
  --tw-text-opacity: 1;
  color: rgb(169 162 157 / var(--tw-text-opacity));
}

.referral .leading-\[120\%\] {
  line-height: 120%;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .ml-\[auto\],
.referral .ml-auto {
  margin-left: auto;
}

/*  */
.referral .absas {
  z-index: 9999999999999;
  position: absolute;
}

.btn-style {
  cursor: no-drop;
}

.referral .MuiPaper-elevation.MuiPaper-rounded {
  background: #57534e;
  border-radius: 0 !important;
  box-shadow: 0px -10px 20px #0d121ca6, -7px -6px #0000006b;
  margin: 0 !important;
  bottom: 62px;
  width: 100%;
}

.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root {
  padding: 0 !important;
  max-height: 340px;
  overflow-y: auto;
  /* border-top: 1px solid #79716b; */
  border-top: 1px solid rgba(252, 100, 50, 0.5);
}

/* Default State */
.select-dropdown {
  background: linear-gradient(
    180deg,
    rgb(28, 28, 28),
    rgb(17, 15, 22)
  ) !important;
  border: 1.5px solid rgba(252, 100, 50, 0.5) !important;
  color: #d6d6d6 !important;
  transition: box-shadow 0.2s ease, background 0.2s ease, border-color 0.2s ease !important;
}

.select-dropdown:hover {
  box-shadow: 0 0 10px rgba(252, 100, 50, 0.3) !important;
}

/* Active State */
.select-dropdown.active {
  background: linear-gradient(
    180deg,
    rgb(28, 28, 28),
    rgba(252, 100, 50, 0.2)
  ) !important;
  border: 1.5px solid #fc6432 !important;
  color: #fc6432 !important;
  font-weight: bold !important;
}

/* Disabled State */
.select-dropdown.disabled {
  background: rgb(17, 15, 22) !important;
  border: 1px solid #57534e !important;
  color: #57534e !important;
  cursor: not-allowed !important;
}

.select-dropdown.disabled:hover {
  box-shadow: none !important;
}

/* Claimable Network Items */
.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root > li {
  background: linear-gradient(180deg, #1e1e1e, #282828) !important;
  border: 1px solid rgba(252, 100, 50, 0.5) !important; /* Apply to All Borders You See Inside This Select Dropdown */
  color: #f0f0f0 !important; /* Network name */
  transition: all 0.3s ease !important;
}

/* Rewards Dollar Amount */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li
  .reward-amount {
  color: #fc6432 !important; /* Bright orange for rewards */
  font-weight: bold !important;
}

/* Disabled Items */
.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root > li.disabledItem {
  background: #1a1a1a !important;
  color: #57534e !important; /* Dimmed text */
  cursor: not-allowed !important;
}

/* Disabled Dollar Amount */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li.disabledItem
  .reward-amount {
  color: #57534e !important; /* Dimmed rewards */
}

/* Disabled Network Icon */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li.disabledItem
  .network-icon {
  filter: grayscale(100%) !important;
  opacity: 0.4 !important; /* Faded icon */
}

/* Active Network Item */
.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root > li.active {
  background: linear-gradient(
    180deg,
    rgba(28, 28, 28, 0.9),
    rgba(252, 100, 50, 0.2)
  ) !important;
  border: 1px solid #fc6432 !important;
  color: #fc6432 !important; /* Highlighted text color */
  font-weight: bold !important;
}

/* Active Rewards Dollar Amount */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li.active
  .reward-amount {
  color: #fc6432 !important;
  font-weight: bold !important; /* Network Name Text */
}

/* Active Network Icon */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li.active
  .network-icon {
  filter: none !important; /* Fully visible */
  opacity: 1 !important; /* No fading */
}

/* Hover Network Item */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li:hover:not(.disabledItem):not(.active) {
  background: linear-gradient(
    180deg,
    rgba(252, 100, 50, 0.1),
    rgba(28, 28, 28, 0.8)
  ) !important;
  border: 1px solid rgba(252, 100, 50, 0.5) !important; /* Softer orange border */
  color: #eef2f6 !important; /* Brightened text for hover effect */
  cursor: pointer !important; /* Show pointer to indicate interactivity */
  transition: all 0.3s ease !important;
}

/* Hover Rewards Dollar Amount */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li:hover:not(.disabledItem):not(.active)
  .reward-amount {
  color: #fc6432 !important; /* Highlight rewards in bright orange */
  font-weight: bold !important; /* Make it slightly bolder on hover */
}

/* Hover Network Icon */
.referral
  .MuiPaper-elevation.MuiPaper-rounded
  .MuiList-root
  > li:hover:not(.disabledItem):not(.active)
  .network-icon {
  filter: none !important; /* Fully visible */
  opacity: 0.9 !important; /* Slight emphasis */
  transition: opacity 0.3s ease !important;
}

/* Desktop and larger tablets */
@media (min-width: 768px) {
  .referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root {
    border-right: 1px solid rgba(252, 100, 50, 0.5);
    /* border-right: 1px solid #79716b; */
    /* Add right border */
  }
}

/* Optional: Ensure no border-right on smaller screens */
@media (max-width: 767px) {
  .referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root {
    border-right: none;
    /* Explicitly remove right border */
  }
}

.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root > li.Mui-selected {
  background: #fc6432;
}

.referral .css-1km1ehz.Mui-selected {
  background-color: rgba(25, 118, 210, 0.08);
}

.referral .css-1km1ehz {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;

  font-family: "Matter Mono", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.referral .stswap-select_placeholder {
  display: none !important;
}

.referral .css-r8u8y9 {
  list-style: none;
  margin: 0px;
  padding: 8px 0px;
  position: relative;
  outline: 0px;
}

.referral .css-pwxzbm {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  position: absolute;
  min-width: 16px;
  min-height: 16px;
  outline: 0px;
}

.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root > li:hover {
  /* background: #79716b; */
  background: #2a2a2a;
}

.referral .MuiPaper-elevation.MuiPaper-rounded .MuiList-root > li {
  padding: 12px 16px !important;
  /* border-bottom: 1px solid #79716b;
  border-left: 1px solid #79716b;
  border-right: 1px solid #79716b; */
  background: #1e1e1e;
}

.referral .w-\[28px\] {
  width: 28px;
}

.referral .h-\[28px\] {
  height: 28px;
}

.referral .mr-\[28px\] {
  margin-right: 28px;
}

.referral .relative {
  position: relative;
}

.referral .text-\[\#EEF2F6\] {
  --tw-text-opacity: 1;
  color: rgb(238 242 246 / var(--tw-text-opacity));
}

.referral .mr-\[40px\] {
  margin-right: 40px;
}

.referral .text-\[\#D7D3D0\] {
  --tw-text-opacity: 1;
  color: rgb(215 211 208 / var(--tw-text-opacity));
}

@media (hover: none) {
  .referral .css-1km1ehz:hover {
    background-color: transparent;
  }
}

.referral .css-1km1ehz:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.referral .css-1km1ehz {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;

  font-family: "Matter Mono", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.new-edge-p {
  font-weight: bold;
  font-size: 20px;
  /* Corresponds to typography-caption1 */
  font-family: "Matter Mono", sans-serif;
}

.gradient-bg tr {
  border: none;
}

.gradient-bg {
  background-color: #fc643291;
  border-radius: 20px 20px 0 0;
}

.center-address {
  min-width: 300px;
}

.new-edge-h3 {
  font-family: "Matter SQ", sans-serif;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-items: baseline;
  gap: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.referral-head {
  font-size: 30px;
  font-family: "Matter SQ", sans-serif;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}

/* .confo {
  flex-direction: column !important;
} */
/*  */
@media (min-width: 765px) {
  .user-list {
    margin-top: 50px;
  }
}

@media (min-width: 600px) {
  .referral .css-1km1ehz {
    min-height: auto;
  }
}

@media (min-width: 480px) {
  .referral .mobile\:flex-row {
    flex-direction: row;
  }
}

.responsive-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .responsive-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .referral .tablet\:w-auto {
    width: auto;
  }

  .referral .tablet\:mt-\[0\] {
    margin-top: 0;
  }

  .referral .tablet\:mb-\[42px\] {
    margin-bottom: 42px;
  }

  .referral .tablet\:mb-\[42px\] {
    margin-bottom: 42px;
  }

  .referral .tablet\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .referral .tablet\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .referral .tablet\:grid-cols-\[1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }

  .referral .tablet\:grid-cols-\[1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }

  .referral .tablet\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .referral .tablet\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .referral .tablet\:backdrop-blur-\[12px\] {
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }

  .referral .tablet\:backdrop-blur-\[12px\] {
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }

  .referral .tablet\:gap-\[80px\] {
    gap: 80px;
  }

  .referral .tablet\:gap-\[80px\] {
    gap: 80px;
  }
}

@media (min-width: 1024px) {
  .referral .laptop\:text-\[32px\] {
    font-size: 38px;
  }

  .referral .laptop\:mb-\[24px\] {
    margin-bottom: 24px;
    margin-top: auto;
  }

  .referral .laptop\:block {
    display: block;
  }

  .referral .laptop\:text-\[14px\] {
    font-size: 14px;
  }

  .referral .laptop\:text-\[20px\] {
    font-size: 20px;
  }

  .referral .laptop\:text-\[20px\] {
    font-size: 20px;
  }

  .referral .laptop\:mt-\[24px\] {
    margin-top: 24px;
  }

  .referral .laptop\:text-\[14px\] {
    font-size: 14px;
  }

  .referral .laptop\:gap-0,
  .referral .laptop\:gap-\[0px\] {
    gap: 0px;
  }

  .referral .laptop\:mt-\[16px\] {
    margin-top: 16px;
  }

  .referral .laptop\:pb-\[16px\] {
    padding-bottom: 16px;
  }

  .referral .laptop\:mb-\[24px\] {
    margin-bottom: 24px;
  }

  .referral .laptop\:gap-\[10px\] {
    gap: 10px;
  }

  .referral .laptop\:mt-\[16px\] {
    margin-top: 16px;
  }

  .referral .laptop\:text-\[14px\] {
    font-size: 14px;
  }

  .referral .laptop\:pb-\[16px\] {
    padding-bottom: 16px;
  }

  .referral .laptop\:gap-\[12px\] {
    gap: 12px;
  }

  .referral .laptop\:p-\[24px\] {
    padding: 32px;
  }

  .referral .laptop\:mt-\[32px\] {
    margin-top: 32px;
  }

  .referral .laptop\:text-\[42px\] {
    font-size: 38px;
  }

  .referral .laptop\:mb-\[16px\] {
    margin-bottom: 16px;
  }

  .referral .laptop\:text-\[16px\] {
    font-size: 16px;
  }

  .referral .laptop\:mb-\[32px\] {
    margin-bottom: 32px;
  }

  .referral .laptop\:pb-\[180px\] {
    padding-bottom: 180px;
  }

  .referral .laptop\:mt-\[64px\] {
    margin-top: 64px;
  }
}

@media (max-width: 391px) {
  .mobile-m-ref-rewards {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .ipad-my-referral-rewards-ernead {
    font-size: 10px;
  }
  .ipad-my-referral-rewards-ernead-margin {
    font-size: 10px;
    margin-right: 50px;
  }
  .ipad-my-referral-rewards-ernead-header {
    font-size: 12px;
  }
}

/* .confirmation-block-reff {
  padding: 25px;
}

@media (max-width: 776px) {
  .confirmation-block-reff {
    padding: 12px;
  }
} */

@media (max-width: 1024px) {
  .topmara {
    margin-top: 80px !important;
  }
}

@media (max-width: 768px) {
  .topmara {
    margin-top: 48px !important;
  }

  .main-nets {
    margin-top: 50px;
  }
}

@media (min-width: 1280px) {
  .referral .desktop\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.my-referrals-icon-wrapper {
  position: relative;
  display: inline-block;
}

.my-referrals-icon-wrapper .badge.radiating-badge {
  position: absolute;
  top: -10px;
  right: -20px;
  background-color: #fc6432;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* or 9999px */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  animation: breathe 1.6s infinite;
}

/* Reuse the same keyframes you have for .liveDot */
@keyframes breathe {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(252, 100, 50, 0.7);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(252, 100, 50, 0);
  }
}
