.fkVKpM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  min-height: calc(-148px + 100vh);
  flex: 1 1 0%;
  z-index: 1;
}

.btn-scale:hover {
  transform: scale(1.03);
}

.top-bb {
  top: -1px !important;
}

.bottom-bb {
  bottom: -1px !important;
}

.left-bb {
  left: -1px !important;
}

.right-bb {
  right: -1px !important;
}

.cop-g {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1; */
  gap: 4px;
  cursor: default !important;
  padding: 0 0 16px;
  background-color: rgb(28, 28, 28);
  color: white;

  /* border-bottom: 1px solid rgb(41 48 61 / .08); */
  transition: all 0.3s ease;
  /* border: 1px solid rgb(41 48 61 / .08); */
  border-radius: 0px !important;
  padding: 20px;
  box-shadow: none;
}

.stake-snova-heading {
  font-family: "Matter SQ", sans-serif;
  font-weight: bold;
}

.lfUtvf {
  width: 100%;
  /* background-image: url(../../assets/img/stake-bg.png); */
  background-size: 100%;
  background-repeat: no-repeat, no-repeat;
  z-index: 1;
  background-position: center top, center bottom;
}

.jus-sp {
  justify-content: space-between !important;
}

.isImg {
  width: 24px;
  height: 24px;
}

.dpCZJJ {
  margin: auto;
  /* width: 1224px; */
  /* min-height: 1100px; */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  gap: 40px;
  padding-top: 60px;
}

.padding220 {
  padding-bottom: 220px;
}

.gCXHAi {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.kWWrlt {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.zgtPq {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.css-d31pb6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.cQzbjF {
  width: fit-content;
}

.css-1p4nx4e {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 16px;
}

.css-11gd710 {
  box-sizing: border-box;
  min-width: 0px;
  margin: 28px 0px;
}

.oOOzz {
  height: 1px;
  background-color: rgb(80, 80, 80);
  border-top: 1px solid rgb(80, 80, 80);
}

.css-doyhi6 {
  box-sizing: border-box;
  margin: 0px 0px 16px;
  min-width: 0px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgb(169, 169, 169);
}

.zgtPq {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.css-1w4lz6v {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgb(255, 153, 1);
}

.this-decor a {
  text-decoration: underline !important;
}

.fkVKpM a {
  color: #fc6432;
  text-decoration: none;
}

.jGtEDF {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 12px;
}

.dbDzoS {
  width: max-content;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 4px;
}

.flex-sp {
  justify-content: space-between !important;
}

.glex {
  flex-direction: column;
  display: flex;
  justify-content: start;
  align-items: start;
}

.cuevGy {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.ghPkTE {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.fkWFah {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 28px;
  margin-bottom: 18px;
}

.epGqjz {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: rgb(169, 169, 169);
}

.activeColorTab {
  color: #fc6432 !important;
}

.dNCYYc {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: rgb(169, 169, 169);
}

.hPfNbk {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.css-4is5xf {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  line-height: 16px;
  color: white;
  font-family: "Matter SQ", sans-serif;
  font-weight: bold;
}

.css-yzh01t {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 16px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.flpGHV {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 0px;
}

.cZUnBP {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

/* .new-bga {
    padding-block: 10px !important;
    background-color: rgb(255, 255, 255, 0.1) !important;
    } */
.stakingForm {
  /* padding: 28px 22px; */
  margin-bottom: 30px;
  /* border-radius: 30px; */
  /* background-color: rgb(255, 255, 255, 0.1) !important; */
}

.tBbNw {
  background-color: transparent;
  border-radius: 0px;
  background-color: rgb(28, 28, 28);
  border-radius: 20px;
  /* 
    */
  padding: 16px;
  width: 100%;
}

.mbtool {
  margin-bottom: 10px !important;
}

@media (max-width: 600px) {
  .stakingForm {
    padding: 25px 15px;
  }
}

@media (max-width: 430px) {
  .stakingForm {
    padding: 15px 10px;
  }
}

.fousnf {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 4px;
  color: rgb(169, 169, 169);
}

.css-5h0skw {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
}

.mvqrr {
  border-radius: 16px;
  background-color: rgb(15, 15, 15);
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 4px 4px);
}

.zgtPq {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.cuevGy {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.fNxtIS {
  flex-wrap: wrap;
  margin: -2px;
}

.dbDzoQ {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 2px;
}

.fgaEeH {
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  background-color: rgb(60, 60, 60);
  color: rgb(200, 200, 200);
}

.fgaEeH:hover {
  color: #fc6432;
  /* Orange text on hover */
  transform: scale(1.05);
  /* Slightly larger on hover */
}

.fNxtIS > * {
  margin: 2px !important;
}

.jkIBMj {
  flex-wrap: wrap;
  margin: -3px;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.gBzCzi {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  gap: 3px;
}

.jkIBMj > * {
  margin: 3px !important;
}

.css-65w75 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 13px;
}

.ibsvru {
  width: 0px;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: rgb(15, 15, 15);
  font-size: 24px;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  appearance: textfield;
}

.eitkWI {
  border-radius: 20px;
  background: rgb(28, 28, 28);
  padding: 8px 12px 8px 8px;
  display: flex;
  color: #ffffff;
  gap: 5.5px;
  font-size: 20px;
}

.cMAzYL {
  background-color: #7f280b;
  min-width: unset;
  color: #fc6432;
  font-size: 14px;
  font-weight: 500;
}

.kscbeX {
  padding: 12px;
  width: 100%;
  height: auto;
  margin: unset;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border-radius: 999px;
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.itOQCZ {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  text-decoration: none;
  font-size: inherit;
  padding: 8px 16px;
  color: white;
  border-radius: 999px;
}

.mb-40 {
  margin-bottom: 40px;
}

.kLaYWH {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 0.25rem;
  line-height: 100%;
  vertical-align: middle;
}

.fAbxMa {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  color: rgb(169, 169, 169);
}

.jHniMi {
  display: grid;
  grid-auto-rows: auto;
}

.ggFMkW {
  cursor: pointer;
  z-index: 1;
}

.eIIHDG * {
  transition: 0.3s ease-in-out;
}

.zgtPq {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.cuevGy {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.css-dzm8si {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 12px;
  color: rgb(252, 100, 50);
}

.eIIHDG * {
  transition: 0.3s ease-in-out;
}

.dccqBT {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 10px;
}

.thissvg {
  fill: #c5c5c5;
}

.top-6 {
  top: auto;
  margin-top: 2px;
}

@media (max-width: 1200px) {
  .dpCZJJ {
    flex-direction: column;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    align-content: center;
  }

  .kWWrlt {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .gCXHAi {
    width: 100vw;
    padding: 0px 16px;
  }
}

[dir="ltr"] .border-s {
  border-left-width: 2px;
}

[dir="ltr"] .border-s {
  border-left-width: 1px;
}

.left-cont .border-\[\#301748\] {
  --tw-border-opacity: 1;
  border-color: #fc6432;
}

.left-cont .gap-10 {
  gap: 2.5rem;
}

.left-cont .flex-col {
  flex-direction: column;
}

.left-cont .flex {
  display: flex;
}

.left-cont .mb-6 {
  margin-bottom: 1.5rem;
}

.left-cont .relative {
  position: relative;
}

[dir="ltr"] .ms-6 {
  margin-left: 2rem;
}

.fill-white {
  fill: white;
}

.faq-question:hover svg path {
  fill: #fc6432;
}

.orange-color {
  color: #fc6432;
}

.oranger {
  background-color: #fc6432;
  padding: 2px 10px;
  border-radius: 9999px;
}

.orangerbg {
  background-color: #fc6432;
  opacity: 0.8;
  color: white;
  padding: 2px 10px;
  border-radius: 9999px;
}

.f1 {
  font-size: 32px;
}

[dir="ltr"] .ms-6 {
  margin-left: 2rem;
}

.left-cont .flex {
  display: flex;
}

[dir="ltr"] .-start-5 {
  left: -1.25rem;
}

.left-cont .text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.left-cont .leading-tight {
  line-height: 1.25;
}

.left-cont .font-bold {
  font-weight: 700;
}

.left-cont .text-lg {
  font-size: 1.25rem;
  font-weight: 600;
}

.left-cont .ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: #fc6432;
}

.left-cont .ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.left-cont .ring-1,
.left-cont .ring-2 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}

.left-cont .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.left-cont .bg-\[\#301748\] {
  --tw-bg-opacity: 1;
  background-color: rgb(28, 28, 28);
}

.left-cont .rounded-full {
  border-radius: 9999px;
}

.left-cont .justify-center {
  justify-content: center;
}

.left-cont .items-center {
  align-items: center;
}

.left-cont .w-9 {
  width: 2.25rem;
}

.left-cont .h-9 {
  height: 2.25rem;
}

.left-cont .flex {
  display: flex;
}

.left-cont .-left-\[1\.2em\] {
  left: -1.2em;
}

.left-cont .absolute {
  position: absolute;
}

.left-cont .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.left-cont .-mt-1 {
  margin-top: -0.25rem;
  font-size: 0.9375rem;
  font-weight: 400;
}

.left-cont .underline {
  text-decoration-line: underline;
  text-decoration-color: #fc6432;
}

.left-cont .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.left-cont .leading-4 {
  line-height: 1rem;
}

.left-cont .italic {
  font-style: italic;
}

.left-cont .text-sm {
  font-size: 0.975rem;
}

.left-cont .mb-10 {
  margin-bottom: 2.5rem;
}

.left-cont ol {
  margin-left: 1rem !important;
}

.left-cont {
  margin-top: 30px;
}

/*  */
.ui-justify-between {
  justify-content: space-between;
}

.ui-w-full {
  width: 100%;
}

.ui-flex {
  display: flex;
}

.ui-flexa p {
  font-size: 14px;
  color: #a0a0a0;
}

.ui-flexa {
  align-items: center;
  justify-content: center;
  display: flex;
}

.ui-flex-col {
  flex-direction: column;
}

.ui-p-4 {
  padding: 1rem;
}

.ui-items-center {
  align-items: center;
}

.ui-text-txt-1 {
  color: #fc6432;
}

.ui-text-txt-2 {
  color: #c5c5c5;
}

.text-txt-4 {
  color: #fc6432;
}

.f3b {
  font-size: 18px;
}

.r1b {
  font-size: 14px;
  font-weight: 700;
}

.r2b {
  font-size: 16px;
  font-weight: 700;
}

.ui-w-full {
  width: 100%;
}

.ui-relative {
  position: relative;
}

.ui-gap-3 {
  gap: 0.75rem;
}

.ui-justify-center {
  justify-content: center;
}

.ui-items-center {
  align-items: center;
}

.ui-flex-wrap {
  flex-wrap: wrap;
}

.ui-uppercase {
  text-transform: uppercase;
}

.ui-mt-2 {
  margin-top: 0.5rem;
}

.ui-border-solid {
  border-style: solid;
}

.ui-border {
  border-width: 1px;
}

.ui-gap-4 {
  gap: 1rem;
}

.ui-items-center {
  align-items: center;
}

.ui-flex-1 {
  flex: 1 1 0%;
}

@media (min-width: 500px) {
  .xs\:ui-min-w-\[360px\] {
    min-width: 360px;
  }
}

@media (min-width: 768px) {
  .md\:ui-p-6 {
    padding: 1.5rem;
  }
}

.ui-border-tile-3-elev {
  border-color: #2c2c2e;
}

.w-\[190px\] {
  width: 190px;
}

.ui-uppercase {
  text-transform: uppercase;
}

.compound-button {
  background: linear-gradient(90deg, #fc6432, #e3562b);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  width: 190px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(252, 100, 50, 0.4);
  /* Subtle glow */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.claim-button {
  background: #1c1c1c;
  /* Dark background */
  color: #fc6432;
  /* Orange text */
  border: 2px solid #fc6432;
  border-radius: 8px;
  width: 190px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.compound-button:hover {
  background: linear-gradient(90deg, #e3562b, #fc6432);
  transform: scale(1.03);
  /* Slight scale effect */
  box-shadow: 0 4px 10px rgba(252, 100, 50, 0.2);
}

.claim-button:hover {
  background: linear-gradient(
    90deg,
    rgba(252, 100, 50, 0.3),
    rgba(227, 86, 43, 0.3)
  );
  color: #fc6432;
  /* Matches the main orange text */
  border-color: #fc6432;
  /* Consistent border */
  transform: scale(1.03);
  /* Adds interactivity */
  box-shadow: 0 4px 10px rgba(252, 100, 50, 0.2);
}

.compound-button:disabled,
.claim-button:disabled {
  pointer-events: none;
  background: #333;
  color: #777;
  border-color: #777;
  transform: none;
  box-shadow: none;
}

.compound-button:disabled:hover,
.claim-button:disabled:hover {
  background: #333;
  transform: none;
  box-shadow: none;
  color: #777;
  border-color: #777;
}

.myrewardstool {
  z-index: 9999 !important;
  background-color: rgba(28, 28, 28, 0.9);
  /* Dark background */
  color: #fc6432 !important;
  /* Orange text for branding */
  padding: 8px 12px;
  /* Inner spacing */
  border-radius: 6px;
  /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for depth */
  text-align: center;
  /* Align text for a polished look */
}

.btn-lga,
.btn-mda {
  font-feature-settings: "salt" on, "ss02" on;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.btn-lga {
  height: 40px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ui-gap-5 {
  gap: 1.25rem;
}

.ui-mt-4 {
  margin-top: 1rem;
}

@media (max-width: 1200px) {
  .gCXHAi {
    width: 100%;
  }
}

/* slider */
.css-13t3sfn .inputs {
  margin-bottom: 24px;
}

.mantine-16d7kiv {
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  display: -webkit-box;
  display: -webkit-flex;
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  touch-action: none;
  position: relative;
}

.mantine-12p37cx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: calc(0.5rem * 2);
  cursor: pointer;
}

.mantine-1tdql7g {
  position: relative;
  height: 0.5rem;
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background: #333539;
  background-color: #333539;
  height: 0.1875rem;
}

.balance-p span {
  text-decoration: underline;
  color: white;
}

.balance-p {
  font-size: 13px;
  color: #c5c5c5;
}

.mantine-1tdql7g::before {
  background: #333539;
  background-color: #333539;
}

.mantine-1tdql7g::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 2rem;
  right: calc(0.5rem * -1);
  left: calc(0.5rem * -1);
  background-color: #e9ecef;
  z-index: 0;
}

.mantine-qjjy1c {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  background-color: #fc6432;
  border-radius: 2rem;
  left: calc(0% - 0.5rem);
  width: calc(0% + 2 * 0.5rem);
}

.mantine-1n7v7zl {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: calc(0.5rem * 2);
  width: calc(0.5rem * 2);
  background-color: #fff;
  border: 0.25rem solid #fc6432;
  color: #fc6432;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  cursor: pointer;
  border-radius: 1000px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition-duration: 100ms;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  height: 0.9875rem;
  width: 0.9875rem;
  background: #140603;
  border: 3.5px solid #fc6432;
}

.mantine-s2z3jd {
  position: absolute;
  right: 0.5rem;
  left: 0.5rem;
}

.mantine-uphwyh {
  position: absolute;
  top: calc(0.5rem / 2);
  z-index: 2;
  height: 0;
  left: 0%;
}

.mantine-1bnwqbd {
  box-sizing: border-box;
  border: 0.125rem solid #e9ecef;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1000px;
  -webkit-transform: translateX(calc(-0.5rem / 2));
  -moz-transform: translateX(calc(-0.5rem / 2));
  -ms-transform: translateX(calc(-0.5rem / 2));
  transform: translateX(calc(-0.5rem / 2));
  background-color: #fff;
  pointer-events: none;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.375rem;
  -webkit-transform: translateX(-0.1875rem) translateY(-0.03125rem);
  -moz-transform: translateX(-0.1875rem) translateY(-0.03125rem);
  -ms-transform: translateX(-0.1875rem) translateY(-0.03125rem);
  transform: translateX(-0.1875rem) translateY(-0.03125rem);
  background: #140603;
  border: 2px solid #fc6432;
  border-color: #fc6432;
  border-color: #fc6432;
}

.moti {
  margin-top: 10px !important;
}

.banner-up .text-text-tip {
  margin-top: 5px;
}

.banner-up .main-gb {
  margin-top: 7.5px;
}

.banner-up .table-cl {
  margin-top: 10px;
}

.smaller-ft {
  color: #777;
  font-size: 14px !important;
}

/* .main-staking-modal .myaa {
  transform: none;
  top: auto;
  left: auto;
  margin: auto;
}

.main-staking-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: max-content;
  right: 0;
  height: max-content;
  margin: auto;
  bottom: 0;
  z-index: 99999 !important;

} */

.acc-ul li {
  text-align: center;
  list-style-type: disc;
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

.mantine-1a7pf42 {
  -webkit-transform: translate(-50%, calc(0.625rem / 2));
  -moz-transform: translate(-50%, calc(0.625rem / 2));
  -ms-transform: translate(-50%, calc(0.625rem / 2));
  transform: translate(-50%, calc(0.625rem / 2));
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fef1ec;
  margin-bottom: 0.3125rem;
  margin-top: 0;
}

.animate-ui-element-transition {
  position: relative;
}

.mantine-zose1j {
  position: absolute;
  top: calc(0.5rem / 2);
  z-index: 2;
  height: 0;
  left: 25%;
}

.mantine-nnvqx1 {
  box-sizing: border-box;
  border: 0.125rem solid #e9ecef;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1000px;
  -webkit-transform: translateX(calc(-0.5rem / 2));
  -moz-transform: translateX(calc(-0.5rem / 2));
  -ms-transform: translateX(calc(-0.5rem / 2));
  transform: translateX(calc(-0.5rem / 2));
  background-color: #fff;
  pointer-events: none;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.375rem;
  -webkit-transform: translateX(-0.1875rem) translateY(-0.03125rem);
  -moz-transform: translateX(-0.1875rem) translateY(-0.03125rem);
  -ms-transform: translateX(-0.1875rem) translateY(-0.03125rem);
  transform: translateX(-0.1875rem) translateY(-0.03125rem);
  background: #140603;
  border: 2px solid #fc6432;
}

.mantine-1n5vmf9 {
  position: absolute;
  top: calc(0.5rem / 2);
  z-index: 2;
  height: 0;
  left: 50%;
}

.mantine-jkqqu7 {
  position: absolute;
  top: calc(0.5rem / 2);
  z-index: 2;
  height: 0;
  left: 75%;
}

.mantine-1dcgt5g {
  position: absolute;
  top: calc(0.5rem / 2);
  z-index: 2;
  height: 0;
  left: 100%;
}

.mantine-1tdql7g::before {
  background: #333539;
  background-color: #333539;
}

.mantine-1tdql7g {
  position: relative;
  height: 0.5rem;
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background: #333539;
  background-color: #333539;
  height: 0.1875rem;
}

.bg-sized {
  border-radius: 20px;
  background-color: rgb(28, 28, 28);
}

.custom-ui-container {
  --border-stroke: #ddd;
  --text-accent: #3498db;

  .container-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
  }

  .container-content > div {
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* flex: 1; */
    gap: 4px;
    cursor: default !important;
    padding: 0 0 16px;
    background-color: rgb(28, 28, 28);
    color: white;
    width: 50%;
    max-width: 50%;
    min-width: 48%;
    /* border-bottom: 1px solid rgb(41 48 61 / .08); */

    transition: all 0.3s ease;

    /* border: 1px solid rgb(41 48 61 / .08); */
    border-radius: 0px !important;
    padding: 20px;
    box-shadow: none;
  }

  .container-content > div:hover {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000,
      0px 0px 2px 0px rgba(0, 0, 28, 0.12),
      0px 4px 20px 0px rgba(0, 0, 28, 0.06);
  }

  .container-content svg {
    margin-bottom: 12px;
    color: var(--text-accent);
  }

  .container-content p {
    font-weight: bold;
    font-size: 1rem;
    font-family: "Matter SQ", sans-serif;
    /* Corresponds to typography-caption1 */
  }

  .container-content h3 {
    font-weight: normal;
    font-size: 1.25rem;
    /* Corresponds to typography-h3 */
    display: flex;
    align-items: baseline;
    gap: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .container-content h3 span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.custom-ui-container .container-content > div:hover {
  transition: all 0.3s ease;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000,
    0px 0px 2px 0px rgba(0, 0, 28, 0.12), 0px 4px 20px 0px rgba(0, 0, 28, 0.06);
}

@media (max-width: 1200px) {
  .custom-ui-container .container-content {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .custom-ui-container .container-content > div {
    width: calc(25% - 12px);
    min-width: calc(25% - 12px);
    max-width: calc(25% - 12px);
  }
}

@media (max-width: 1024px) {
  .custom-ui-container .container-content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .custom-ui-container .container-content > div {
    width: 48%;
    min-width: 48%;
    max-width: 48%;
  }
}

@media (max-width: 768px) {
  .dab-k.no-dbk {
    width: 100%;
  }

  .mobile-faq {
    margin-top: 30px;
  }

  .custom-ui-container .container-content {
    flex-wrap: wrap;

    .gCXHAi {
      width: 100vw;
      padding: 0px 16px;
    }
  }

  [dir="ltr"] .border-s {
    border-left-width: 2px;
  }

  [dir="ltr"] .border-s {
    border-left-width: 1px;
  }

  .left-cont .border-\[\#301748\] {
    --tw-border-opacity: 1;
    border-color: #fc6432;
  }

  .left-cont .gap-10 {
    gap: 2.5rem;
  }

  .left-cont .flex-col {
    flex-direction: column;
  }

  .left-cont .flex {
    display: flex;
  }

  .left-cont .mb-6 {
    margin-bottom: 1.5rem;
  }

  .left-cont .relative {
    position: relative;
  }

  [dir="ltr"] .ms-6 {
    margin-left: 2rem;
  }

  .fill-white {
    fill: white;
  }

  .faq-question:hover svg path {
    fill: #fc6432;
  }

  .orange-color {
    color: #fc6432;
  }

  .oranger {
    background-color: #fc6432;
    padding: 2px 10px;
    border-radius: 9999px;
  }

  .orangerbg {
    background-color: #fc6432;
    opacity: 0.8;
    color: white;
    padding: 2px 10px;
    border-radius: 9999px;
  }

  .f1 {
    font-size: 32px;
  }

  [dir="ltr"] .ms-6 {
    margin-left: 2rem;
  }

  .left-cont .flex {
    display: flex;
  }

  [dir="ltr"] .-start-5 {
    left: -1.25rem;
  }

  .left-cont .text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .left-cont .leading-tight {
    line-height: 1.25;
  }

  .left-cont .font-bold {
    font-weight: 700;
  }

  .left-cont .text-lg {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .left-cont .ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: #fc6432;
  }

  .left-cont .ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

  .left-cont .ring-1,
  .left-cont .ring-2 {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 transparent);
  }

  .left-cont .text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .left-cont .bg-\[\#301748\] {
    --tw-bg-opacity: 1;
    background-color: rgb(28, 28, 28);
  }

  .left-cont .rounded-full {
    border-radius: 9999px;
  }

  .left-cont .justify-center {
    justify-content: center;
  }

  .left-cont .items-center {
    align-items: center;
  }

  .left-cont .w-9 {
    width: 2.25rem;
  }

  .left-cont .h-9 {
    height: 2.25rem;
  }

  .left-cont .flex {
    display: flex;
  }

  .left-cont .-left-\[1\.2em\] {
    left: -1.2em;
  }

  .left-cont .absolute {
    position: absolute;
  }

  .left-cont .text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .left-cont .-mt-1 {
    margin-top: -0.25rem;
    font-size: 0.9375rem;
    font-weight: 400;
  }

  .left-cont .underline {
    text-decoration-line: underline;
    text-decoration-color: #fc6432;
  }

  .left-cont .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .left-cont .leading-4 {
    line-height: 1rem;
  }

  .left-cont .italic {
    font-style: italic;
  }

  .left-cont .text-sm {
    font-size: 0.975rem;
  }

  .left-cont .mb-10 {
    margin-bottom: 2.5rem;
  }

  .left-cont ol {
    margin-left: 1rem !important;
  }

  .left-cont {
    margin-top: 30px;
  }

  /*  */
  .ui-justify-between {
    justify-content: space-between;
  }

  .ui-w-full {
    width: 100%;
  }

  .ui-flex {
    display: flex;
  }

  .ui-flexa p {
    font-size: 14px;
    color: #a0a0a0;
    font-style: italic;
  }

  .ui-flexa {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ui-flex-col {
    flex-direction: column;
  }

  .ui-p-4 {
    padding: 1rem;
  }

  .ui-items-center {
    align-items: center;
  }

  .ui-text-txt-1 {
    color: #fc6432;
  }

  .ui-text-txt-2 {
    color: #c5c5c5;
  }

  .text-txt-4 {
    color: #fc6432;
  }

  .f3b {
    font-size: 18px;
  }

  .r1b {
    font-size: 14px;
    font-weight: 700;
  }

  .r2b {
    font-size: 16px;
    font-weight: 700;
  }

  .ui-w-full {
    width: 100%;
  }

  .ui-relative {
    position: relative;
  }

  .ui-gap-3 {
    gap: 0.75rem;
  }

  .ui-justify-center {
    justify-content: center;
  }

  .ui-items-center {
    align-items: center;
  }

  .ui-flex-wrap {
    flex-wrap: wrap;
  }

  .ui-uppercase {
    text-transform: uppercase;
  }

  .ui-mt-2 {
    margin-top: 0.5rem;
  }

  .ui-border-solid {
    border-style: solid;
  }

  .ui-border {
    border-width: 1px;
  }

  .ui-gap-4 {
    gap: 1rem;
  }

  .ui-items-center {
    align-items: center;
  }

  .ui-flex-1 {
    flex: 1 1 0%;
  }

  @media (min-width: 500px) {
    .xs\:ui-min-w-\[360px\] {
      min-width: 360px;
    }
  }

  @media (min-width: 768px) {
    .md\:ui-p-6 {
      padding: 1.5rem;
    }
  }

  .ui-border-tile-3-elev {
    border-color: #2c2c2e;
  }

  .w-\[190px\] {
    width: 190px;
  }

  .ui-uppercase {
    text-transform: uppercase;
  }

  .compound-button {
    background: linear-gradient(90deg, #fc6432, #e3562b);
    color: #ffffff;
    border: none;
    border-radius: 8px;
    width: 190px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(252, 100, 50, 0.4);
    /* Subtle glow */
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }

  .compound-button:disabled {
    background: linear-gradient(90deg, #cfcfcf, #bfbfbf);
    /* Neutral gray gradient */
    color: #ffffff;
    /* Retain white text for readability */
    box-shadow: none;
    /* Remove glow and shadow effects */
    cursor: not-allowed;
    /* Standard pointer indication for disabled buttons */
    opacity: 0.6;
    /* Add transparency to make it appear inactive */
  }

  .claim-button:disabled {
    background: #1c1c1c;
    /* Retain the dark background */
    color: #a5a5a5;
    /* Muted gray text */
    border-color: #a5a5a5;
    /* Match border to muted text */
    box-shadow: none;
    /* Remove shadow effects */
    cursor: not-allowed;
    opacity: 0.6;
    /* Dim for visual clarity */
  }

  .cooldown-timer {
    font-size: 12px;
    color: #a5a5a5;
    text-align: center;
    margin-top: 8px;
  }

  .claim-button {
    background: #1c1c1c;
    /* Dark background */
    color: #fc6432;
    /* Orange text */
    border: 2px solid #fc6432;
    border-radius: 8px;
    width: 190px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
  }

  .compound-button:not(:disabled):hover {
    background: linear-gradient(90deg, #e3562b, #fc6432);
    transform: scale(1.03);
    /* Slight scale effect */
    box-shadow: 0 4px 10px rgba(252, 100, 50, 0.2);
  }

  .claim-button:not(:disabled):hover {
    background: linear-gradient(
      90deg,
      rgba(252, 100, 50, 0.3),
      rgba(227, 86, 43, 0.3)
    );
    color: #fc6432;
    /* Matches the main orange text */
    border-color: #fc6432;
    /* Consistent border */
    transform: scale(1.03);
    /* Adds interactivity */
    box-shadow: 0 4px 10px rgba(252, 100, 50, 0.2);
  }

  .myrewardstool {
    z-index: 9999 !important;
    background-color: rgba(28, 28, 28, 0.9);
    /* Dark background */
    color: #fc6432 !important;
    /* Orange text for branding */
    padding: 8px 12px;
    /* Inner spacing */
    border-radius: 6px;
    /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    text-align: center;
    /* Align text for a polished look */
  }

  .btn-lga,
  .btn-mda {
    font-feature-settings: "salt" on, "ss02" on;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-ui-container .container-content > div {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

/* Mini Faq */
.mini-faq .ui-border-solid {
  border-style: solid;
}

.mini-faq .ui-flex-col {
  flex-direction: column;
}

.mini-faq .ui-w-full {
  width: 100%;
}

.mini-faq .ui-flex {
  display: flex;
}

.mini-faq .ui-pt-4 {
  padding-top: 0.2rem;
}

.mini-faq .ui-pr-6 {
  padding-right: 1.5rem;
}

.mini-faq .ui-pb-4 {
  padding-bottom: 0.2rem;
}

.mini-faq .ui-border-\[\#131314\] {
  --tw-border-opacity: 1;
  border-color: rgba(128, 128, 128, 0.3);
}

.mini-faq .ui-border-\[\#131314\]:last-child {
  border-bottom: none;
}

.faq-slide:first-child {
  border-top-width: 1px !important;
  border-bottom-width: 0px !important;
}

.faq-slide:last-child {
  border-top-width: 0px !important;
  border-bottom-width: 1px !important;
}

.mini-faq .ui-border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.mini-faq .ui-border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.mini-faq .hover\:ui-text-primary:hover {
  color: #fc6432;
}

.mini-faq .hover\:ui-text-primary:hover svg {
  fill: #fc6432;
}

.ftsize {
  font-size: 18px;
  font-family: "MATTER SQ", sans-serif;
  font-weight: bold;
}

.wallet-icon-small {
  height: 15px;
}

.this20 {
  height: 20px !important;
}

.mini-faq .hover\:ui-text-primary svg {
  fill: white;
}

.mini-faq .ui-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.mini-faq .ui-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mini-faq .ui-fill-txt-2 {
  fill: var(--txt-2);
}

.mini-faq .ui-items-center {
  align-items: center;
}

.mini-faq .ui-cursor-pointer {
  cursor: pointer;
}

.mini-faq .ui-w-full {
  width: 100%;
}

.mini-faq .ui-flex {
  display: flex;
}

.mini-faq .ui-flex-1 {
  flex: 1 1 0%;
}

.mini-faq .b1sb,
.mini-faq .b2sb {
  font-weight: 700;
}

.mini-faq .b1sb {
  font-size: 16px;
  font-family: "Matter SQ", sans-serif;
}

.mini-faq .ui-justify-center {
  justify-content: center;
}

.mini-faq .ui-items-center {
  align-items: center;
}

.mini-faq .ui-rotate-180,
.mini-faq .ui-scale-100 {
  transform: rotate(180deg);
}

.mini-faq .ui-opacity-0 {
  opacity: 0;
}

.mini-faq .ui-h-0 {
  height: 0;
}

.mini-faq .ui-hidden {
  display: none;
}

.mini-faq .ui-pt-0 {
  padding-top: 0;
}

.mini-faq .ui-max-w-\[850px\] {
  max-width: 850px;
}

.mini-faq .b2 {
  font-size: 16px;
}

.mini-faq .ui-opacity-100 {
  opacity: 1;
}

.mini-faq .ui-pt-2 {
  padding-top: 0.5rem;
}

.mini-faq .ui-pb-2 {
  padding-bottom: 0.5rem;
}

.mini-faq .ui-max-w-\[850px\] {
  max-width: 850px;
  padding-bottom: 0.7rem;
}

.mini-faq .b2 {
  font-size: 13px;
}

@media (max-width: 550px) {
  .oranger {
    font-size: 11px;
  }
}

/*  */
.transaction-table-container {
  padding: 20px;
  max-width: 100%;
  width: 100%;
  margin: auto;
  border-radius: 8px;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.no-dbk {
  min-height: auto !important;
}

.flex-line {
  display: flex;
  align-items: center;
}

#my-tooltip,
.styles-module_dark__xNqje,
.tootltip {
  z-index: 9;
  color: #fc6432;
}

.dab-k .max-diffee.page_tableContainer__p8Vj_ {
  width: 100%;
  max-width: none;
  min-width: auto;
}

.date-sm {
  font-size: 16px !important;
}

.mobile-faq {
  display: block;
  margin-bottom: 60px;
}

.desktop-faq {
  display: none;
}

@media (min-width: 768px) {
  .date-sm {
    font-size: 18px !important;
  }

  .mobile-faq {
    display: none;
  }

  .desktop-faq {
    display: block;
  }

  .left-cont {
    margin-bottom: 20px !important;
    margin-top: 45px !important;
  }

  /* .left-cont {
    margin-bottom: 67px !important;
    margin-top: 93px !important;
  } */

  .stakingForm {
    /* margin-bottom: 50px !important; */
  }
}

@media (max-width: 400px) {
  .date-sm {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .dpCZJJ {
    gap: 6px;
  }

  .padding160 {
    padding-bottom: 80px;
  }
}

.table-wrappers table,
.table-wrappers {
  /* table-layout: auto !important; */
}

.transaction-table td {
  width: max-content;
}

.transaction-table th,
.transaction-table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}

.transaction-table th {
  font-weight: bold;
}

.amount-cell {
  display: flex;
  flex-direction: column;
}

.amount-snova {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
}

.snova-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.amount-dollar {
  font-size: 0.9em;
  color: gray;
}

.sections-container {
  position: relative;
}

.overlay-icon-staking {
  position: absolute;
  top: 60%;
  width: 87px;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* Ensure the overlay does not interfere with interactions */
}

.modl-over-staking {
  display: block;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgb(0 0 0 / 0.75);
  z-index: 9999998;
}

.myaa-staking {
  max-width: 100%;
  width: 550px;
  padding: 1.5rem;
  background-color: #1a1a1a;
  border: 1px solid #343434;
  border-radius: 0.25rem;
  z-index: 9999999 !important;
}
@media (max-width: 400px) {
  .myaa-staking {
    width: 350px;
    padding: 0.5rem;
  }
}
/* Adjust as needed for responsiveness */
@media (min-width: 400px) and (max-width: 767px) {
  .myaa-staking {
    width: 370px;
    padding: 0.5rem;
  }
}

/* Tooltip */
.myrewardstool {
  z-index: 9999 !important;
  background-color: rgba(28, 28, 28, 0.9);
  color: #fc6432 !important;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.heo-staking {
  top: 73%;
}

@media (max-width: 767px) {
  .heo-staking {
    top: 73%;
  }
}

.heo-staking,
.myaa-staking {
  z-index: 9999999 !important;
  /* Make sure modal content is above the overlay */
}

/* Slider Thumb and Tooltip Styles */
.slider-thumb {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 0.9875rem;
  width: 0.9875rem;
  background-color: #140603;
  border: 3.5px solid #fc6432;
  border-radius: 50%;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s,
    border 0.2s;
}

.slider-thumb.thumb-active {
  background-color: #fc6432;
  border: 3px solid #e3562b;
  box-shadow: 0 0 8px rgba(252, 100, 50, 0.6);
  transform: translate(-50%, -50%) scale(1.2);
}

.slider-tooltip {
  position: absolute;
  bottom: 20px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fc6432;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 9999;
}

.join-presale-btn {
  transition: transform 0.2s ease;
}

.join-presale-btn:hover {
  transform: scale(1.03);
}

/* TESTING MODALS DELETE IN PRODUCTION*/
.modal-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.modal-content {
  max-width: 550px;
  width: 100%;
  padding: 2rem;
  background-color: #1a1a1a;
  border: 1px solid #343434;
  border-radius: 0.25rem;
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.modal-content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.modal-content .form-group {
  margin-bottom: 20px;
}

.modal-content .form-group label {
  display: block;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}

.modal-content .form-group input {
  width: 100%;
  padding: 10px;
  background-color: #151515;
  border: 1px solid #fc6432;
  color: #fff;
  border-radius: 5px;
}

.modal-content .staking-button {
  width: 100%;
  margin-bottom: 10px;
}

.modal-content .staking-button.cancel-button {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.modal-content .staking-button + .staking-button {
  margin-left: 0;
}

.staking-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-width: 260px;
  font-size: 26px;
  text-decoration: none;
  color: #fc6432;
  background: transparent;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid #fc6432;
}

.staking-button:hover {
  background-color: rgba(252, 100, 50, 0.2);
}

.staking-button.cancel-button {
  background-color: transparent;
  color: #fff;
}

/* TESTING MODALS DELETE IN PRODUCTION*/
