.panel-1 {
  position: relative;
  background: url(../../../assets/img/bg-1.webp) 50% no-repeat;
  background-size: cover;
  height: 920px;
}
.ellipses {
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: -300px;
}

.ellipses:before {
  content: " ";
  display: block;
  width: 100%;
  bottom: 300px;
  height: 100%;
  position: absolute;
  background: url(../../../assets/img/big-logo.png) center 675px no-repeat,
    url(../../../assets/img/bg-2.png) bottom no-repeat;
  background-size: auto, auto;
}
.security-icon,
.community-icon,
.ai-icon {
  z-index: 1;
  position: absolute;
  left: 1px;
  top: 2px;
  bottom: 0;
  right: 0;
}
.eclipsetar {
  position: relative;
  width: max-content;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.ellipses:after {
  content: " ";
  display: block;
  width: 100%;
  bottom: 0;
  height: 333px;
  position: absolute;
  background-color: #0a0a0a !important;
}
.section-3_acceleratorIcon___t779 {
  background: #121212;
  bottom: 270px;
  left: calc(50% - 360px);
}
.section-3_acceleratorText__6QKzJ,
.section-3_growthAgencyText__7_0T0,
.section-3_launchpadText__GU3Zb {
  position: absolute;
  bottom: -30px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.6;
}
.section-3_popup__q_f81 {
  display: none;
  position: absolute;
  top: auto;
  bottom: 122px;

  left: -160px;
  width: 425px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: hsla(0, 0%, 100%, 0.1);
}
.section-3_h6__eii5y {
  color: white;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.section-3_body2__NoQ5Q,
.section-3_summaryText__TLd5u {
  color: white;
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.section-3_launchpadIcon__RsDk7 {
  background: #121212;
  bottom: 480px;
  right: calc(50% - 52px);
}
.section-3_launchpadIcon__RsDk7 .section-3_popup__q_f81 {
  position: absolute;
  bottom: 121px;
  top: auto;
}

.section-3_popup__q_f81 {
  display: none;
  position: absolute;
  top: -180px;
  left: auto;
  bottom: 122px;
  width: 425px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: hsla(0, 0%, 100%, 0.1);
}
.section-3_h6__eii5y {
  color: #fff;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.section-3_body2__NoQ5Q,
.section-3_summaryText__TLd5u {
  color: white;
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.section-3_acceleratorIcon___t779,
.section-3_growthAgencyIcon__baZ47,
.section-3_launchpadIcon__RsDk7 {
  position: absolute;
  width: 104px;
  height: 104px;
  border-radius: 999px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.security-icon {
  height: 65px;
  transform: translate(11px, 15px);
}

.ai-icon {
  height: 80px;
  transform: translate(11px, 8.5px);
}

.community-icon {
  height: 80px;
  transform: translate(11px, 7px);
}

.section-3_growthAgencyIcon__baZ47 {
  background: #121212;
  bottom: 270px;
  right: calc(50% - 360px);
}
.section-3_growthAgencyText__7_0T0 {
  white-space: nowrap;
}
.section-3_growthAgencyIcon__baZ47 .section-3_popup__q_f81 {
  top: auto;
}
.cornet {
  padding-block: 15px;
}

.section-3_popup__q_f81 {
  display: none;
  position: absolute;
  top: auto;
  bottom: 122px;
  left: -160px;
  width: 425px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: hsla(0, 0%, 100%, 0.1);
}
.inner-pad {
  padding-top: 100px;
}
.section-3_h6__eii5y {
  color: white;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.section-3_body2__NoQ5Q,
.section-3_summaryText__TLd5u {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.ellipses {
  display: none;
}
.eclipse-main .mt-96 {
  margin-top: 24rem;
}
.section-3_line__t6gF0 {
  background: url(../../../assets/img/line.png) 50% no-repeat;
  top: -400px;
  right: calc(50% - 3px);
  position: absolute;
  width: 6px;
  height: 103px;
}
.section-3_acceleratorIcon2__112og {
  /* background: url(../../../assets/img/img-004.png) hsla(0, 0%, 100%, 0.1) 50%
    no-repeat; */
  top: -270px;
  right: calc(50% - 68px);
  position: absolute;
  width: 136px;
  height: 136px;
  border-radius: 999px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
/*  */

.section-3-mobile_container__vXiWF .panel-1 {
  position: relative;
  background: url(../../../assets/img/bg-1.webp) 50% no-repeat;
  background-size: cover;
  height: 570px;
}
.eclipse-main .z-10 {
  z-index: 10;
}
.section-3-mobile_container__vXiWF h1 {
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.section-3-mobile_container__vXiWF h1 {
  color: #fff;

  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-\[\#E2CD6C\] {
  --tw-text-opacity: 1;
  color: #fc6432;
}
.section-3-mobile_container__vXiWF .panel-2 {
  min-height: 600px;
}
.eclipse-main .items-center {
  align-items: center;
}
.eclipse-main .flex-col {
  flex-direction: column;
}
.eclipse-main .flex {
  display: flex;
}
.eclipse-main .mt-96 {
  margin-top: 24rem;
}
.section-3-mobile_popup__YpZUj {
  width: 90%;
  position: relative;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  zoom: 2;
  margin-bottom: 24px;
  border-radius: 24px;
  background: hsla(0, 0%, 100%, 0.1);
}
.section-3-mobile_launchpadIcon__5MIFC {
  background: #121212;
}

.section-3-mobile_acceleratorIcon__DHFAg,
.section-3-mobile_growthAgencyIcon__NLI0d,
.section-3-mobile_launchpadIcon__5MIFC {
  position: relative;
  width: 104px;
  height: 104px;
  border-radius: 999px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-bottom: 24px;
}
.section-3-mobile_h6__tvakd {
  color: white;
  text-align: center;
  margin-top: 14px;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.section-3-mobile_body2__BWU13,
.section-3-mobile_summaryText__nMxaU {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.section-3-mobile_body2__BWU13 {
  align-self: stretch;
  margin-top: 14px;
  font-size: 16px;
}

.section-3_container__Rbmzm {
  position: relative;
  padding-top: 3rem;
}
.section-3-mobile_growthAgencyIcon__NLI0d {
  background: #121212;
}
.section-3-mobile_acceleratorIcon__DHFAg {
  background: #121212;
}
.section-3-mobile_line__Em3Ui {
  background: url(../../../assets/img/line.png) 50% no-repeat;
  position: relative;
  width: 6px;
  height: 103px;
  zoom: 2;
}
.section-3-mobile_acceleratorIcon2__NvFUR {
  background: #121212;
  position: relative;
  width: 136px;
  height: 136px;
  border-radius: 999px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  zoom: 2;
  margin-bottom: 24px;
}
.mt-5as {
  margin-top: 50px;
}
.section-3-mobile_container__vXiWF .panel-1 {
  background: url(../../../assets/img/big-logo.png) center 222px no-repeat,
    url(../../../assets/img/bg-2.png) bottom no-repeat,
    url(../../../assets/img/ellipse-med.png) bottom no-repeat,
    url(../../../assets/img/ellipse-small.png) bottom no-repeat;
  background-size: auto, auto, auto, auto, auto, cover;
}
.section-3_container__Rbmzm h1 {
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-family: "Matter SQ", sans-serif !important;
}

.section-3_container__Rbmzm h1 {
  color: #fff;

  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-\[\#E2CD6C\] {
  --tw-text-opacity: 1;
  color: #fc6432;
}
@media (min-width: 768px) {
  .section-3_container__Rbmzm h1 {
    font-size: 50px;
    line-height: 1;
  }
  .section-3_container__Rbmzm {
    padding-top: 5rem;
  }
}

@media (min-width: 1024px) {
  .ellipses {
    display: flex;
  }
}
.section-3_popup__q_f81:after {
  content: " ";
  position: absolute;
  width: 60px;
  height: 17px;
  background: url(../../../assets/img/arrow.png) 50% no-repeat;
  bottom: -17px;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.container-medium-thesis {
  padding-top: 50px;
}
.section-3_acceleratorIcon___t779:hover .section-3_popup__q_f81,
.section-3_growthAgencyIcon__baZ47:hover .section-3_popup__q_f81,
.section-3_launchpadIcon__RsDk7:hover .section-3_popup__q_f81 {
  display: flex;
}
.mtless {
  margin-top: 9rem !important;
}
@media (max-width: 600px) {
  .section-3-mobile_container__vXiWF .panel-2 {
    zoom: 0.5;
  }
  .mbl-hide {
    display: none !important;
  }
  .mtless {
    margin-top: 12.5rem !important;
  }
}
@media (max-width: 410px) {
  .mtless {
    margin-top: 13rem !important;
  }
}
.safari .panel-1 {
  /* Styles that you have for devices below 1024px width */
}
/* Hide elements not needed in Safari */
.safari .ellipses {
  display: none;
}
/* Existing styles for devices below 1024px width */
@media (max-width: 1024px) {
  .panel-1 {
    /* Styles */
  }
  .ellipses {
    display: none;
  }
}
