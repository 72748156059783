.rank-updated .leading-\[120\%\] {
    line-height: 120%;
}.rank-updated 

.font-medium {
    font-weight: 500;
}.rank-updated 
.text-\[16px\] {
    font-size: 16px;
}.rank-updated 
.pr-\[20px\] {
    padding-right: 20px;
}.rank-updated 
.pl-\[12px\] {
    padding-left: 12px;
}.rank-updated 
.items-center {
    align-items: center;
}.rank-updated 
.flex {
    display: flex
;
}.rank-updated 
.mb-\[18px\] {
    margin-bottom: 18px;
}.rank-updated 
.mb-\[8px\] {
    margin-bottom: 8px;
}.rank-updated 

._line_18dqk_1 {
    position: relative;
    height: 20px;
    border-radius: 8px 8px 0 0;
    border-top: 1px solid rgba(250, 250, 250, .12);
    border-right: 1px solid rgba(250, 250, 250, .12);
    border-left: 1px solid rgba(250, 250, 250, .12);
    transition: .3s;
}.rank-updated 
._line_18dqk_1:before {
    content: "";
    position: absolute;
    right: 8px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: #fafafa1f;
}.rank-updated 
._line_18dqk_1:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: #fafafa1f;
}.rank-updated 
.gap-\[6px\] {
    gap: 6px;
}.rank-updated 
.icon-bta{
    margin-left: 10px;
    width: 25px;
    height: 25px;
    display: inline-block
}.rank-updated 
.flex-col {
    flex-direction: column;
}.rank-updated 
.flex {
    display: flex
;
}.rank-updated 
.leading-\[120\%\] {
    line-height: 120%;
}.rank-updated 

.font-medium {
    font-weight: 500;
}.rank-updated 
.text-\[16px\] {
    font-size: 16px;
}.rank-updated 
.pr-\[20px\] {
    padding-right: 20px;
}.rank-updated 
.pl-\[12px\] {
    padding-left: 12px;
}.rank-updated 
.items-center {
    align-items: center;
}.rank-updated 
.flex {
    display: flex
;
}.rank-updated 
.mb-\[18px\] {
    margin-bottom: 18px;
}.rank-updated 
.pl-\[8px\] {
    padding-left: 8px;
}.rank-updated 

.flex-\[1_1_12\%\] {
    flex: 1 1 12%;
}.rank-updated 
.flex-\[1_1_25\%\] {
    flex: 1 1 25%;
}.rank-updated 
.gap-\[8px\] {
    gap: 8px;
}.rank-updated 
.max-w-\[20px\] {
    max-width: 20px;
}.rank-updated 
.gap-\[8px\] {
    gap: 8px;
}.rank-updated 

.items-center {
    align-items: center;
}.rank-updated 
.flex {
    display: flex
;
}.rank-updated 
.flex-\[1_1_20\%\] {
    flex: 1 1 20%;
}.rank-updated 
.text-right {
    text-align: right;
}.rank-updated 

.flex-\[1_1_18\%\] {
    flex: 1 1 18%;
}.rank-updated 
.gap-\[6px\] {
    gap: 6px;
}.rank-updated 

.flex-col {
    flex-direction: column;
}.rank-updated 
.flex {
    display: flex
;
}.rank-updated 
.text-\[\#FAFAFA\] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}.rank-updated 

.leading-\[120\%\] {
    line-height: 120%;
}.rank-updated 
.font-medium {
    font-weight: 500;
}.rank-updated 
.text-\[16px\] {
    font-size: 16px;
}.rank-updated 
.pr-\[19px\] {
    padding-right: 19px;
}.rank-updated 
.pl-\[11px\] {
    padding-left: 11px;
}.rank-updated 
.py-\[11px\] {
    padding-top: 11px;
    padding-bottom: 11px;
}.rank-updated 
.border-borderColor {
    border-color: #ffffff38;
}.rank-updated 
.border, .border-\[1px\] {
    border-width: 1px;
}.rank-updated 
.rounded-\[8px\] {
    border-radius: 8px;
}.rank-updated 
.flex-\[1_1_12\%\] {
    flex: 1 1 12%;
}.rank-updated 
.justify-center {
    justify-content: center;
}.rank-updated 

.items-center {
    align-items: center;
}.rank-updated 
.w-\[32px\] {
    width: 32px;
}.rank-updated 
.h-\[32px\] {
    height: 32px;
}.rank-updated 
.flex {
    display: flex
;
}.rank-updated 
.relative {
    position: relative;
}.rank-updated 
.top-0 {
    top: 0;
}.rank-updated 

.left-0 {
    left: 0;
}.rank-updated 
.right-0 {
    right: 0;
}.rank-updated 
.flex-evm {
    display: flex; 
    gap:10px;
    align-items: center;
}.rank-updated 
.bottom-0 {
    bottom: 0;
}.rank-updated 
.absolute {
    position: absolute;
}.rank-updated 

.rotate-\[-90deg\] {
    --tw-rotate: -90deg;
    transform: translate(0, 0) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);
}.rank-updated 

.rotate-\[180deg\] {
    --tw-rotate: 180deg;

    transform: translate(0, 0) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);}.rank-updated 
.rotate-\[90deg\] {
    --tw-rotate: 90deg;

    transform: translate(0, 0) rotate(var(--tw-rotate)) skew(0) skewY(0) scaleX(1) scaleY(1);}.rank-updated 
.flex-\[1_1_20\%\] {
    flex: 1 1 20%;
}.rank-updated .text-right {
    text-align: right;
}.rank-updated 

.flex-\[1_1_18\%\] {
    flex: 1 1 18%;
}.rank-updated 
.cnt-width{
    max-width:300px !important
}
@media (max-width: 768px) {
    .rank-updated .leading-\[120\%\] {
        line-height: 120%;
    }.rank-updated 
    .font-medium {
        font-weight: 500;
    }.rank-updated 
    .text-\[16px\] {
        font-size: 16px;
    }.rank-updated 
    .p-\[12px\] {
        padding: 12px;
    }.rank-updated 
    .border-borderColor {
        border-color: #ffffff38;
    }.rank-updated 
    .border, .border-\[1px\] {
        border-width: 1px;
    }.rank-updated 
    .rounded-\[8px\] {
        border-radius: 8px;
    }.rank-updated 
    .gap-\[16px\] {
        gap: 16px;
    }.rank-updated 
    .flex-col {
        flex-direction: column;
    }.rank-updated 
    .flex {
        display: flex
    ;
    }.rank-updated 
    .pr-\[16px\] {
        padding-right: 16px;
    }.rank-updated 
    .pl-\[8px\] {
        padding-left: 8px;
    }.rank-updated 
    .py-\[8px\] {
        padding-top: 8px;
        padding-bottom: 8px;
    }.rank-updated 
    .justify-between {
        justify-content: space-between;
    }.rank-updated 
    .items-center {
        align-items: center;
    }.rank-updated 
    .flex {
        display: flex
    ;
    }.rank-updated 
    .relative {
        position: relative;
    }.rank-updated 
    ._Image_15l8v_1._load_15l8v_7 {
        opacity: 1;
    }.rank-updated 
    .max-w-full {
        max-width: 100%;
    }.rank-updated 
    .w-full {
        width: 100%;
    }.rank-updated 
    .max-h-full {
        max-height: 100%;
    }.rank-updated 
    .z-\[-1\] {
        z-index: -1;
    }.rank-updated 
    .top-0 {
        top: 0;
    }.rank-updated 
    .left-0 {
        left: 0;
    }.rank-updated 
    .absolute {
        position: absolute;
    }.rank-updated 
    ._Image_15l8v_1 {
        max-width: 100%;
        min-height: 1px;
        transition: all .8s ease-out .1s;
        opacity: 0;
    }.rank-updated 
    .items-center {
        align-items: center;
    }.rank-updated 
    .text-\[\#FAFAFA\] {
        --tw-text-opacity: 1;
        color: rgb(250 250 250 / var(--tw-text-opacity));
    }.rank-updated 
    .justify-center {
        justify-content: center;
    }.rank-updated 
    .items-center {
        align-items: center;
    }.rank-updated 
    .w-\[32px\] {
        width: 32px;
    }.rank-updated 
    .h-\[32px\] {
        height: 32px;
    }.rank-updated 
    .flex {
        display: flex
    ;
    }.rank-updated 
    .dir-mbl{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }.rank-updated 
    .mr-\[12px\] {
        margin-right: 12px;
    }.rank-updated 
    .relative {
        position: relative;
    }.rank-updated 
    .gap-\[8px\] {
        gap: 8px;
    }.rank-updated 
    .max-w-\[20px\] {
        max-width: 20px;
    }.rank-updated 
    .gap-\[12px\] {
        gap: 12px;
    }.rank-updated 
    .justify-between {
        justify-content: space-between;
    }
}