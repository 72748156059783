.services .centerText {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.go-apply {
  display: inline-block;
  transform: translate(-6.5px, 6.5px);
  height: 22px !important;
}

.btn-go-apply {
  width: 267px;
}

.apply-subheading {
  font-size: 30px;
}
.new-ido.box-text {
  padding: 15px 25px;
  height: auto;
  width: 200px;
}
.bg-tp {
  background-color: transparent !important;
}
.services .launch_section {
  /* padding: 60px 60px 80px; */
  padding: 30px 30px 40px;
  padding-inline: 0 !important;
  background: url(../../../assets/img/stacknova-apply.webp);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
}
.services .launch_section .middleEl {
  max-width: 900px;
  position: relative;
  z-index: 2;
}
.services .launch_section img {
  max-width: 120px;
  height: auto;
  margin-inline: auto !important;
  margin: 25px 0 40px;
}
.services .launch_section .titleBox {
  font-size: 2.3em;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.orange {
  color: #fc6432;
}
.services .launch_section p {
  font-size: 1.1em;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.7);
  padding: 15px 0;
}
.services .launch_section .showmore {
  color: #fff;
  border: solid 1px #fff;
}

.services .showmore {
  margin-top: 10px;
  display: inline-block;
  margin-bottom: 0;
}
.showmore {
  padding: 15px 25px;
  color: #8c8c8c;
  font-weight: 600;
  border: solid 1px #646464;
  margin: 40px auto;
  width: 200px;
  text-align: center;
  border-radius: 5px;
  display: block;
}
.effect {
  transition: all 0.45s ease-in-out;
  -webkit-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
}
.showmore:hover {
  color: #fc6432 !important;
  border: solid 1px #fc6432 !important;
}
@media (max-width: 1080px) {
  .services .launch_section {
    background: url(../../../assets/img/stacknova-apply-mob.webp);
    background-attachment: unset;
    background-position: center;
    background-size: cover;
    position: relative;
  }
}
@media (max-width: 600px) {
  .no-mbl {
    display: none;
  }
}
