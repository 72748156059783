.referral .overflow-hidden {
  overflow: hidden;
}
.referral {
  overflow-x: hidden;
}
.referral .max-h-\[100vh\] {
  max-height: 100vh;
}
.referral .opacity-\[0\.2\] {
  opacity: 0.2;
}
.referral .object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.referral .flex-\[0_0_50\%\] {
  flex: 0 0 50%;
}
.referral .opacity-\[0\.2\] {
  opacity: 0.2;
}
.referral .object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.referral .flex-\[0_0_50\%\] {
  flex: 0 0 50%;
}
.referral .h-full {
  height: 100%;
}
.referral .hidden {
  display: none;
}
.referral .shadow-bottom:after {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  transform: rotate(180deg);
  background: linear-gradient(#000, #00000038);
}
.referral .shadow-bottom {
  z-index: -9;
}
.joSyap {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -9;
  width: 100%;
  height: 100%;
}
.jWroVr {
  background-color: #121314 !important;
}

.bfugXH {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  width: 100vw;
  height: 100%;
  background-color: #121314;
}
.hBaNbX {
  position: relative;
  top: 0px;
  z-index: 20;
  width: 100%;
  height: 100%;
  margin: 0px auto;
}

.hBaNbX {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1312px;
  margin: 0px auto;
  padding: 0px 16px;
  box-sizing: border-box;
}
.gfuaku {
  top: 0px;
  height: 140px;
}

.hFbZhE {
  position: absolute;
  left: 0px;
  z-index: 15;
  width: 100%;
  height: 60px;
  background: linear-gradient(0deg, transparent, #121314);
}
.icrZgZ {
  bottom: 0px;
  transform: rotate(180deg);
}
.limpYk {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTMxMiAzMjgiCiAgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB3aWR0aD0iMTMxMiIgaGVpZ2h0PSIzMjgiPgogIDxkZWZzPgogICAgPHBhdHRlcm4gaWQ9ImdyaWQtcGF0dGVybiIgd2lkdGg9IjE2NCIgaGVpZ2h0PSIxNjQiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8cGF0aCBkPSJNIDE2NCAwIEwgMCAwIDAgMTY0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjQiCiAgICAgICAgdmVjdG9yRWZmZWN0PSJub24tc2NhbGluZy1zdHJva2UiCiAgICAgIC8+CiAgICA8L3BhdHRlcm4+CiAgPC9kZWZzPgogIDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZC1wYXR0ZXJuKSIgLz4KPC9zdmc+);
  background-repeat: repeat;
  background-position: center top;
  opacity: 0.02;
}
.dHUIdR {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 16;
  width: 100%;
  height: 100%;
}
.dHUIdR {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
}
.jLlnFM {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
}
.iFdytO {
  grid-column: span 1;
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}
.iFdytO {
  grid-column: span 1;
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}
.iFdytO {
  grid-column: span 1;
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}
@media (min-width: 1024px) {
  .referral .laptop\:block {
    display: block;
  }
}
