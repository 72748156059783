.referral .pt-\[96px\] {
  padding-top: 96px;
}

.referral .p-\[8px\] {
  padding: 8px;
}

.referral .self-start {
  align-self: flex-start;
}

.referral .gap-\[10px\] {
  gap: 10px;
}

.referral .items-center {
  align-items: center;
}

.referral .inline-flex {
  display: inline-flex;
}

.referral ._btn_dcpj6_1 svg {
  transition: all 0.3s ease;
}

.referral .text-\[\#fff\],
.referral .text-\[white\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .justify-center {
  justify-content: center;
}

.referral .flex {
  display: flex;
}

.referral .mt-\[24px\] {
  margin-top: 24px;
}

.referral .mb-\[12px\] {
  margin-bottom: 12px;
}

.referral .relative {
  position: relative;
}

.referral .left-\[50px\] {
  left: 50px;
}

.referral .bottom-\[20px\] {
  bottom: 20px;
}

.referral .text-defaultOrange {
  color: #fc6432;
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[42px\] {
  font-size: 42px;
}

.referral .mb-\[12px\] {
  margin-bottom: 12px;
}

.referral .top-\[0\],
.referral .top-\[0px\] {
  top: 0;
}

.referral .right-\[40px\] {
  right: 40px;
}

.referral .text-\[\#D6D6D6\] {
  --tw-text-opacity: 1;
  color: rgb(214 214 214 / var(--tw-text-opacity));
}

.referral .text-\[16px\] {
  font-size: 16px;
}

.referral .flex-col {
  flex-direction: column;
}

.referral .flex {
  display: flex;
}

.referral .mt-\[24px\] {
  margin-top: 24px;
}

.referral .text-\[\#F5F5F4\] {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.referral .font-\[500\] {
  font-weight: 500;
}

.referral .text-\[20px\] {
  font-size: 20px;
}

.referral .text-center {
  text-align: center;
}

.referral .mt-\[12px\] {
  margin-top: 12px;
}

.refer-snova-heading {
  font-family: "Matter SQ", sans-serif;
  font-weight: bold;
}

.referral .relative {
  position: relative;
}

.referral ._copyWrapper_1fjrj_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 38px;
  padding: 5px 12px;
  background: rgb(28, 28, 28);
  max-width: 100%;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: linear-gradient(135deg, #1c1c1c, rgba(252, 100, 50, 0.2));
}

.referral ._copyText_1fjrj_12 {
  color: #fc6432;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.7px;
}

.spbt {
  margin-bottom: 26px !important;
}

.referral ._copyText_1fjrj_14 {
  color: #d6d6d6;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.7px;
}

@media (max-width: 429px) {
  .referral ._copyText_1fjrj_12 {
    font-size: 12px;
  }
}

.referral ._copyBtn_1fjrj_20 {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .referral .laptop\:text-center {
    text-align: center;
  }

  .referral .laptop\:text-\[64px\] {
    font-size: 70px;
  }

  .referral .laptop\:text-center {
    text-align: center;
  }

  .referral .laptop\:max-w-\[504px\] {
    max-width: 520px;
  }

  .referral .laptop\:pt-\[132px\] {
    padding-top: 132px;
  }
}

@media (max-width: 1000px) {
  .logo-behind {
    z-index: -5;
  }
}

@media (max-width: 600px) {
  .desk-only2 {
    display: none;
  }

  .text-mb {
    text-align: center;
  }
}