.graphic-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.predictor-icon {
  height: 3rem;
  display: inline;
  margin-right: 5px;
}

.div-block-150 {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.graphic_glow {
  opacity: 0.4;
  object-fit: fill;
  position: absolute;
}

.cardgrid_glow-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.image-30 {
  opacity: 0.49;
  pointer-events: none;
}
.cardgrid {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  display: flex;

  position: relative;
}
.graphic-inner {
  height: 100%;
  position: relative;
}
.cardgrid-row {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}
.card-regular {
  padding: 2rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border-radius: 1rem;
  background-color: #131214;
  /* background-color: rgba(19, 18, 20, 0.5); */
  border: 1px solid rgba(255, 255, 255, 0.3);
  flex-direction: column;
  display: flex;
}
.icon-1x1-medium {
  width: 3rem;
  height: 2rem;
}
.w-embed:before,
.w-embed:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}
.w-embed:after {
  clear: both;
}
h4 {
  letter-spacing: -0.01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1;
}

.text-color-secondary {
  /* color: #bda68e; */
  color: rgba(255, 255, 255, 0.7);
}
.text-size-regular {
  font-size: 1.125rem;
  line-height: 1.4;
}
/* .card-regular {
  padding: 2rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border-radius: 1rem;
  background-color: #121314;
  border: 1px solid rgba(255, 255, 255, 0.3);
  flex-direction: column;
  display: flex;
} */

.card-highlight {
  height: 100%;
  border-radius: 1rem;
  background-color: rgba(252, 100, 50, 0.69);
  border: 1px solid rgb(246 220 202 / 0.4);
  position: relative;
  overflow: hidden;
}
.glow-wraper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.image-26 {
  width: 130%;
  max-width: none;
}
.right-0 {
  right: 0 !important;
}
.card-highlight_content {
  height: 100%;
  padding: 2rem;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
  position: relative;
}
.padding-block {
  padding-block: 85px;
}
.text-color-primary-invert {
  color: white;
}
.text-color-primary-invert {
  color: white;
}
.text-size-regular {
  font-size: 1.125rem;
  line-height: 1.4;
}

.button_primary {
  padding: 1rem 2rem;
  color: offwhite;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  background-color: rgb(18 19 20);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 7rem;
  font-weight: 600;
  transition: background-color 0.1s, color 0.2s;
  display: block;
  position: relative;
  overflow: hidden;
}
.circle-section {
  height: 100%;
}
@media screen and (max-width: 991px) {
  .button_primary,
  .button_primary.is-text {
    white-space: nowrap;
  }
  .cardgrid {
    flex-direction: column;
    justify-content: flex-start;
  }
  .cardgrid-row {
    width: 100%;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .button_primary {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
  .text-size-regular {
    font-size: 0.9rem;
  }
  .text-size-regular {
    font-size: 0.9rem;
  }
  .circle-section {
    width: 500px;
  }
  h4 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 600px) {
  .padding-block {
    padding-block: 30px;
  }
  .section-padding.whyus {
    padding-block: 30px !important;
    padding-top: 0px !important;
  }
  .scroll-my-32 {
    margin-block: 0 30px;
  }
  /* .circle-section {
    width: -webkit-fill-available;
  } */
}

@media screen and (max-width: 510px) {
  /* .circle-section {
    width: 450px;
  } */
}

@media screen and (max-width: 600px) {
  .circle-section,
  .graphic-inner {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 400px) {
  .mobile-market-pred {
    font-size: 10px !important;
  }
}
