.jss638 {
  margin-bottom: 20px;
}
.alag {
  color: white;
  font-size: 18px;
  font-family: "Matter Mono", sans-serif;
}
.sub-btn {
  margin-top: 1rem;
}

.footer-logo {
  width: 150px;
}

.sub-btn button {
  outline: 2px solid transparent;
  padding: 0 1rem;
  height: 3rem;
  font-weight: 700;

  font-size: 18px;
  border-radius: 30px;
  border: 1px solid rgb(20 5 35);
  background-color: transparent;
  outline-offset: 2px;
}
.social-ico {
  display: flex;
  gap: 10px;
}
.social-ico li a {
  display: flex;
  transition: all 0.3s ease;
  align-items: center;
  padding: 10px 10px;
  border-radius: 100%;
}
.social-ico li a:hover {
  transition: all 0.3s ease;
  background-color: transparent;
}
.cus-li {
  display: flex;
}

.jss741 {
  display: flex;
  padding: 24px 0px;
  border-top: 2px solid #f2f5fa;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 767.95px) {
  .jss741 {
    align-items: flex-start;
    flex-direction: column;
  }
}
.jss742 {
  margin-right: 32px;
  align-items: center;
}
@media (min-width: 768px) {
  .jss742 {
    width: 75%;
    gap: 40px;
    margin-right: 0;
  }
}
@media (max-width: 767.95px) {
  .jss742 {
    margin-bottom: 24px;
  }
}
.jss743 {
  color: white;
  flex-grow: 1;
  font-size: 15px;
}
@media (max-width: 959.95px) {
  .jss743 {
    font-size: 14px;
  }
}
@media (max-width: 767.95px) {
  .jss743 {
    width: 100%;
  }
}
.jss743 a {
  color: inherit;
  transition: color 0.2s;
  text-decoration: none;
}
.jss743 a:hover,
.jss743 a:focus {
  color: #fc6432 !important;
}
.jss744 {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 767.95px) {
  .jss744 {
    width: 100%;
    margin-top: 16px;
    justify-content: flex-start;
  }
}
.jss745 {
  margin-right: 48px;
}
.jss745:last-child {
  margin: 0;
}
@media (max-width: 1439.95px) {
  .jss745 {
    margin-right: 24px;
  }
}
.first-cl:hover {
  color: white !important;
  opacity: 0.8 !important;
}
.first-cl {
  display: flex;
  margin: 0;
  opacity: 0.8;
  gap: 8px;
  border: none !important;
  align-items: start;
}
.first-cl a:hover {
  color: #fc6432 !important;
  transition: all 0.3s ease;
}
.first-cl a {
  color: white;
  transition: all 0.3s ease;
  text-decoration: none;
  display: flex;
  align-items: start;
}
.l-gp {
  padding-left: 0.5rem;
  opacity: 0.8;
  color: white;
  border-left: 1px solid lightgray;
  height: fit-content;
  text-decoration: none;

  transition: all 0.3s ease;
  padding-right: 0.5rem;
}
.l-gp:hover {
  color: #fc6432 !important;
  transition: all 0.3s ease;
}
@media (max-width: 1024px) {
  .jss741 {
    flex-direction: column;
    align-items: start;
    gap: 35px;
  }
  .jss742 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .jss742 {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
  .jss741 {
    gap: 0;
  }
  .cus-li {
    flex-wrap: wrap;
    gap: 5px;
  }
  .first-cl {
    padding-left: 0;
  }
}
/*  */

.footer_footer__Hhmm8 {
  padding-bottom: 30px !important;
}
.footer_wrapper__qdczL {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer_box__WbJW_ {
  padding: 24px;
  border-radius: 25px;
  /* background: #151515; */
  background: #1a1a1a;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.footer_title__QdQ4j {
  text-align: center;
  font: 700 12px / 1.455;
  letter-spacing: 0.05em;
  color: white;
  text-transform: uppercase;
}
/*  */

.footer-socials_socials_link__QdqJI span {
  font: 500 24px / 1.255 Matter Mono;
  letter-spacing: -0.02em;
  color: white;
}
.footer-socials_socials__85gHT > a:hover svg {
  fill: #fc6432 !important;
}

.footer-socials_socials__85gHT > a:hover {
  border: 1px solid #fc6432 !important;

  transition: all 0.3s ease-in;
}
.footer-socials_socials__85gHT > a {
  flex-grow: 0;
  border: 1px solid transparent;
  flex-shrink: 0;
  transition: all 0.3s ease-in;
  background-color: black;
  flex-basis: calc(20% - 19.2px);
  max-width: calc(20% - 19.2px);
}

.footer-socials_socials_link__QdqJI {
  padding: 30px 14px;
  border-radius: 17px;
  background: transparent;
  border: 2px solid transparent;
  transition: border 0.2s ease;
  font-size: 0;
}
.footer-socials_socials__85gHT {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 24px;
}
.footer-socials_socials_wrapper__k9UV2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.footer-socials_socials_link__QdqJI img,
.footer-socials_socials_link__QdqJI svg {
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  fill: hsla(0, 0%, 67%, 0.5);
  transition: fill 0.2s ease;
}

.footer-nav_root__tqeWW {
  display: flex;
  align-items: center;
  gap: 65px;
}

.footer-nav_content__wBpDg {
  max-width: 212px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer-nav_title__Jf6Ag {
  font: 500 36px / 1.15 Clash Display;
  letter-spacing: -0.015em;
  color: white;
}
.footer-nav_text__XNB93 {
  font: 400 16px / 1.5;
  color: white;
}

.footer-nav_copyright__vzn9_ {
  font: 400 16px / 1.5;
  color: white;
  white-space: nowrap;
}
/*  */

.footer-nav_nav__92SOe {
  flex-grow: 1;
  padding: 45px;
  border-radius: 20px;
  background: black;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 30px;
}
.footer_title__QdQ4j b {
  font: inherit;
  color: #fc6432;
}
.footer-nav_nav_link__NagoM a {
  transition: all 0.3s ease;
}
.footer-nav_nav_link__NagoM:hover,
.footer-nav_nav_link__NagoM a:hover span {
  color: #fc6432;
  transition: all 0.3s ease;
}
.footer_footer__Hhmm8 li:hover {
  color: inherit !important;
}
.footer-nav_nav_list__htZdT {
  max-width: 550px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
.footer-nav_nav_item__XJ8A_ {
  display: flex;
  flex-direction: column;
}
.footer-nav_nav_title__tBH4B {
  font: 700 16px / 1.5;
  letter-spacing: 0.05em;
  color: white;
  text-transform: uppercase;
}

.footer-nav_nav_link__NagoM {
  margin-top: 20px;
  font: 400 16px / 1.5;
  letter-spacing: 0.05em;
}

/*  */
@media screen and (max-width: 1124px) {
  .footer-socials_socials_link__QdqJI span {
    font-size: 20px;
  }
  .footer-socials_socials_link__QdqJI img,
  .footer-socials_socials_link__QdqJI svg {
    width: 26px;
    height: 26px;
  }
  .footer-socials_socials_link__QdqJI {
    gap: 8px;
  }
}
@media screen and (max-width: 991px) {
  .footer-nav_nav__92SOe {
    padding: 28px;
  }
  .footer-nav_root__tqeWW {
    gap: 36px;
  }
  .footer-socials_socials__85gHT > a {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(20% - 9.6px);
    max-width: calc(20% - 9.6px);
  }
  .footer-socials_socials__85gHT {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 12px;
  }
}
@media screen and (max-width: 768px) {
  .footer-nav_title__Jf6Ag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-nav_nav__92SOe {
    width: 100%;
    order: -1;
  }

  .footer-nav_content__wBpDg {
    text-align: center;
  }
  .footer-nav_root__tqeWW {
    flex-direction: column;
  }
  .footer-socials_socials_link__QdqJI span {
    display: none;
  }
  .footer-socials_socials_link__QdqJI svg {
    fill: #acacac;
  }
}

@media screen and (max-width: 565px) {
  .footer-nav_nav_link__NagoM {
    white-space: nowrap;
  }
  .footer-nav_nav_list__htZdT {
    flex-wrap: wrap;
    gap: 40px;
  }
  .footer-nav_copyright__vzn9_ {
    margin-top: 24px;
  }
  .footer-socials_socials__85gHT > a {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(20% - 7.2px);
    max-width: calc(20% - 7.2px);
  }
  .footer-socials_socials_link__QdqJI {
    padding: 17px;
    border-radius: 12px;
  }
  .footer-socials_socials__85gHT {
    display: flex;
    flex-wrap: wrap;
    gap: 9px 9px;
  }
  .footer_box__WbJW_ {
    padding: 28px 15px;
  }
  .footer-socials_socials_link__QdqJI img,
  .footer-socials_socials_link__QdqJI svg {
    width: 100%;
    height: auto;
  }
  .footer-socials_socials_link__QdqJI {
    padding: 15px;
  }
}
/*  */
/* COokie banner */
.cookie-banner .translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: #1a1a1a;
}
.cookie-banner .w-full {
  width: 100%;
}
.ex-wid {
  width: 125px !important;
  min-width: auto !important;
}
.ms-3a {
  margin-left: 20px;
}
.cookie-banner .z-\[100\] {
  z-index: 100;
}
.cookie-banner .left-\[50\%\] {
  left: 50%;
}
.cookie-banner .bottom-\[20px\] {
  bottom: 20px;
}
.cookie-banner .container {
  position: relative;
  padding: 0 16px;
  margin: 0 auto;
  max-width: 1272px;
  width: 100%;
  height: 100%;
}
.cookie-banner .container {
  width: 100%;
}
.cookie-banner .py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}
.cookie-banner .px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}
.cookie-banner .flex-col {
  flex-direction: column;
}
.cookie-banner .flex {
  display: flex;
}
.cookie-banner .relative {
  position: relative;
}
.cookie-banner .max-w-\[68px\] {
  max-width: 68px;
}
.cookie-banner .mb-\[16px\] {
  margin-bottom: 16px;
}
.cookie-banner .text-\[\#D6D6D6\] {
  --tw-text-opacity: 1;
  color: rgb(214 214 214 / var(--tw-text-opacity));
}
.cookie-banner .font-normal {
  font-weight: 400;
}
.cookie-banner .text-\[14px\] {
  font-size: 14px;
}
.cookie-banner .grow {
  flex-grow: 1;
}
.cookie-banner .mb-\[32px\] {
  margin-bottom: 32px;
}
.cookie-banner .top-\[20px\] {
  top: 20px;
}
.cookie-banner .right-\[16px\] {
  right: 16px;
}
.cookie-banner .absolute {
  position: absolute;
}
@media (min-width: 480px) {
  .cookie-banner .container {
    max-width: 480px;
  }
}
@media (min-width: 640px) {
  .cookie-banner .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .cookie-banner .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .cookie-banner .container {
    max-width: 1250px;
  }
}
@media (min-width: 1024px) {
  .cookie-banner .laptop\:py-\[12px\] {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 1024px) {
  .cookie-banner .laptop\:px-\[24px\] {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .cookie-banner .laptop\:items-center {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .cookie-banner .laptop\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .cookie-banner .laptop\:max-w-\[1148px\] {
    max-width: 1148px;
  }
}

@media (min-width: 1024px) {
  .cookie-banner .laptop\:mb-0,
  .cookie-banner .laptop\:mb-\[0\] {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .cookie-banner .laptop\:pl-\[16px\] {
    padding-left: 16px;
  }
}

@media (min-width: 1024px) {
  .cookie-banner .laptop\:mb-0,
  .cookie-banner .laptop\:mb-\[0\] {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .cookie-banner .laptop\:ml-\[32px\] {
    margin-left: 32px;
  }
}

@media (min-width: 1024px) {
  .cookie-banner .laptop\:static {
    position: static;
  }
}
@media (max-width: 1200px) {
  .cookie-banner .w-full {
    width: 94% !important;
  }
}
@media (max-width: 1024px) {
  .cookie-banner .container {
    width: 100% !important;
    max-width: none !important;
  }
  .ex-wid {
    width: 100% !important;
  }
  .ms-3a.ex-wid {
    margin: 0;
    margin-top: 25px;
  }
  .cookie-banner .max-w-\[68px\] {
    width: 62px;
    text-align: center;
    margin-inline: auto;
  }
  .cookie-banner .top-\[20px\] {
    top: 30px;
  }
  .text-midc {
    text-align: center;
  }
}
