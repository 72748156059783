.heo {
  top: 41%;
}

@media (max-width: 767px) {
  .heo {
    top: 37%;
  }
}

.styles_launchpadApp__cRj7u {
  padding-block: 100px 50px;
  overflow-x: hidden;
}

.transaction-link {
  height: 20px;
  display: inline;
  transform: translateX(4.5px);
}

.purchase-summary {
  height: 20px;
  display: inline;
  margin-right: 10px;
  transform: translateY(-1.5px);
}

.your-tokens {
  color: #fc6432;
  font-weight: bold;
  font-size: 24px;
}

.to-os.main-tp {
  bottom: 29px;
  left: 67%;
}

.styles_launchpadApp__cRj7u .mt-\[100px\] {
  margin-top: 100px;
}
.launchapp .text-\[44px\] {
  font-size: 44px;
  font-weight: bold;
}
.launchapp .gap-\[10px\] {
  gap: 10px;
}
.launchapp .gap-\[14px\] {
  gap: 14px;
}
.launchapp .mt-5 {
  margin-top: 30px;
}
.launchapp .items-center {
  align-items: center;
}
.launchapp .text-green {
  --tw-text-opacity: 1;
  color: #fc6432;
}
.launchapp .absolute {
  position: absolute;
}
.launchapp .opacity-80 {
  opacity: 0.8;
}
.launchapp .bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(29 237 131 / var(--tw-bg-opacity));
}
.launchapp .rounded-full {
  border-radius: 9999px;
}
.launchapp .animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.launchapp .w-\[17px\] {
  width: 17px;
}
.launchapp .h-\[17px\] {
  height: 17px;
}
.launchapp .m-0 {
  margin: 0;
}
.launchapp .top-\[-3px\] {
  top: -3px;
}
.launchapp .left-\[-3px\] {
  left: -3px;
}
.launchapp .absolute {
  position: absolute;
}
.launchapp .bg-orange {
  --tw-bg-opacity: 1;
  background-color: #fc6432;
}
.launchapp .rounded-full {
  border-radius: 9999px;
}
.launchapp .w-\[11px\] {
  width: 11px;
}
.launchapp .h-\[11px\] {
  height: 11px;
}
.launchapp .m-0 {
  margin: 0;
}
.launchapp .styles_launchpadApp__cRj7u .whitespace-nowrap {
  white-space: unset !important;
}
.launchapp .mt-\[20px\] {
  margin-top: 20px;
}

.launchapp .styles_triangle__J5S87 {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid #fc6432;
}
.launchapp .font-medium {
  font-weight: 500;
}
.launchapp .text-\[24px\] {
  font-size: 18px;
}
.launchapp .font-medium {
  font-weight: 500;
}
.launchapp .m-0 {
  margin: 0;
}
.launchapp .w-\[100\%\] {
  width: 100%;
}
.launchapp .mr-\[-250px\] {
  margin-right: -120px;
}
.launchapp .top-\[-16px\] {
  top: -16px;
}
.launchapp .left-\[-16px\] {
  left: -16px;
}
.launchapp .styles_launchpadShadow__iZexE {
  /* box-shadow: 0 549px 154px 0 #370e0013, 0 351px 141px 0 #4411000b,
    0 198px 119px 0 #3f10001c, 0 88px 88px 0 #3b0f0024, 0 22px 48px 0 #45110020; */
}
.top-\[-16px\] {
  top: -16px;
}

.left-\[-16px\] {
  left: -16px;
}
.rotate-\[270deg\] {
  --tw-rotate: 270deg;
}
.text-image-container {
  display: flex;
  align-items: center;
}

.text-image-container h2 {
  margin: 2px;
}

.purchase-success {
  height: auto !important;
  margin-left: 1em;
}

/* .claim-success {
  height: auto !important;
  margin-left: 1em;
} */
.rotate-180,
.rotate-\[270deg\] {
  transform: translate(0, 0) rotate(var(--tw-rotate)) skewX(0) skewY(0)
    scaleX(1) scaleY(1);
}
.left-\[-16px\] {
  left: -16px;
}
.bottom-\[-16px\] {
  bottom: -16px;
}
.launchapp .eor {
  flex-direction: row-reverse;
}
.launchapp .styles_launchpadBlackShadow__w0bSk {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 140px;
}

.mya.myasp {
  /* width: 550px; */
}

.myaa {
  max-width: 100%;
  width: 550px;
  padding: 1.5rem;
  background-color: #1a1a1a;
  border: 1px solid #343434;
  border-radius: 0.25rem;
  z-index: 9999999 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -32%);
}

@media (max-width: 400px) {
  .myaa {
    width: 350px;
    padding: 0.5rem;
  }
}
/* Adjust as needed for responsiveness */
@media (min-width: 400px) and (max-width: 767px) {
  .myaa {
    width: 370px;
    padding: 0.5rem;
  }
}

.heo {
  z-index: 9999999 !important;
}

.modl-over {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgb(0 0 0 / 0.75);
  z-index: 9999998;
}

.network-icon {
  height: 25px;
  display: inline;
  margin-right: 7.5px;
}

.payment-icon {
  height: 25px;
  margin-right: 7.5px;
}

.mibjz {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mibj {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 776px) {
  .mibjz {
    font-size: 18px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .mibj {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
}

.bjpar {
  border-top: 1px dashed #bcbcbc;
  border-bottom: 1px dashed #bcbcbc;
  padding-block: 20px;
  font-size: 15px;
}

.bjpar2 {
  border-top: 1px dashed #bcbcbc;
  border-bottom: 1px dashed #bcbcbc;
  padding-block: 20px;
  font-size: 15px;
}

@media (max-width: 776px) {
  .bjpar {
    border-top: 1px dashed #bcbcbc;
    border-bottom: 1px dashed #bcbcbc;
    padding-block: 10px;
    font-size: 15px;
  }
  .bjpar2 {
    border-top: 1px dashed #bcbcbc;
    border-bottom: 1px dashed #bcbcbc;
    padding-block: 20px;
    font-size: 15px;
  }
}
.invite-text {
  font-size: 14px;
  margin-bottom: 8px;
}

@media (max-width: 776px) {
  .bjpar2 {
    padding-block: 8px;
  }
  .invite-text {
    font-size: 10px;
  }
}

@media (max-width: 1024px) {
  .launchapp .eor {
    flex-direction: column-reverse;
  }
  .launchapp .styles_launchpadApp__cRj7u .mt-\[100px\] {
    align-items: center;
  }
}
@media (max-width: 991px) {
  .launchapp .styles_launchpadApp__cRj7u {
    padding-inline: 20px;
  }
  .launchapp .lg\:min-w-fit {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .launchapp .lg\:mr-\[-100px\] {
    margin-right: -70px;
  }
}
@keyframes ping {
  75%,
  to {
    transform: scale(2);
    opacity: 0;
  }
}
@media (max-width: 1080px) {
  .launchapp .text-\[44px\] {
    font-size: 40px;
  }
}
@media (max-width: 630px) {
  .launchapp .text-\[44px\] {
    font-size: 24px;
  }
  .launchapp .text-\[24px\] {
    font-size: 14px;
  }
  .styles_launchpadApp__cRj7u .mt-\[100px\] {
    margin-top: 70px;
  }
}

@media (max-width: 430px) {
  .launchapp .text-\[44px\] {
    white-space: nowrap !important;
  }
}
/*  */
.table-cl {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
}

.table-header {
  font-weight: bold;
  text-align: left;
  padding: 8px;
  border: 1px solid #fc6432;
}

.table-cell {
  padding: 8px;
  border: 1px solid #fc6432;
}
/*  */
.invite-card {
  width: 100%;
  padding: 8px;
  background-color: rgba(252, 100, 50, 0.15);
  color: #fff;
  border-radius: 10px;
  text-align: center;
}

.your-tokens-bg {
  background: rgba(252, 100, 50, 0.05);
}

.column-dark {
  background: rgba(252, 100, 50, 0.4);
}

.column-light {
  background: rgba(252, 100, 50, 0.1);
}

.invite-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

/* 
.invite-link-container {
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 5px;
} */

.invite-link-title {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
}

.purchased-snova {
  display: inline;
  /* margin-left: 10px; */
  transform: translate(-7.5px, -2px);
}

.referral-coins {
  height: 23px;
  display: inline;
  transform: translateX(3.5px);
}

.purchase-success {
  height: 100px;
  width: 100px;
  align-items: center;
}

.claim-success {
  height: 130px;
  width: 130px;
  align-items: center;
}

.confirmation-block {
  display: flex;
  justify-content: center;
}

._copyBtn_1fjrj_20.we img {
  width: 20px;
}
.invite-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(252, 100, 50, 0.4);
  padding: 8px 10px;
  gap: 5px;
  border-radius: 5px;
}

.invite-link span {
  font-size: 14px;
}

.cross-ic {
  z-index: 99999999999;

  cursor: pointer;
}
.space-between {
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 16px;
  border-bottom: 1px solid rgb(48, 48, 48);
}
.cross-ic path,
.cross-ic rect {
  fill: rgb(146, 146, 146);
}
.scale-head {
  font-size: 18px;
  font-weight: bold;
}
.cross-ic:hover path,
.cross-ic:hover rect {
  fill: rgb(231, 231, 231);
}
.cross-ic svg {
  transform: scale(1.45);
}

.copy-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}
.no-tp {
  border-top: none !important;
}

.add-space {
  margin-left: 3.5px;
}

.main-gb {
  gap: 16px;
}
.main-gb a {
  width: 100% !important;
  min-width: none;
  max-width: none;
}
.table-cl td {
  border-left: none;
  /* width: 242px; */
}
.table-cell {
  border-right: none;
}
.nb,
.table-header {
  border-bottom: none !important;
}
@media (max-width: 600px) {
  .mya.myasp {
    width: 95%;
    overflow: auto;
    height: 100vh;
    padding: 1rem;
  }
  .mbl-hidden {
    display: none !important;
  }
  .main-gb {
    flex-direction: column;
  }
  .main-gb a {
    padding-inline: 0 !important;
  }
  .table-cell {
    font-size: 15px;
  }
  .table-header {
    font-size: 15px;
  }
  .to-os.main-tp {
    bottom: 28px;
    left: 47%;
  }
}
@media (max-width: 400px) {
  .mya.myasp {
    padding-inline: 1rem;
  }
  .space-between {
    padding: 0px 0px 8px;
  }
}
/* src/Card.css */
.card-stam {
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  max-width: none;
  width: 100%;
}
.table-header.no-tp.nobot {
  border-bottom: none !important;
}
.nmop.table-header {
  /* border-top: 3px solid #fc6432 !important; */
}
.double-blk .purchased-snova {
  /* width: 25px; */
  height: 30px;
}
.double-blk .your-tokens {
  font-size: 20px;
}

.gap-custom-tx-modal {
  gap: 0.5rem;
}
@media (max-width: 767px) {
  .gap-custom-tx-modal {
    gap: 0.25rem;
  }
}

.card-item-stam {
  /* padding: 15px; */
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.card-item-stam:last-child {
  border-bottom: none;
}

ol.list-stam {
  padding: 0;
  list-style-position: inside !important ;
  list-style: decimal;
  /* margin: 10px 0 0 0; */
}

.list-item-stam:first-child {
  border-radius: 4px 4px 0 0;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  background: rgba(252, 100, 50, 0.4);
}
.list-item-stam {
  border: 1px solid #fc6432;
  padding: 10px;
  border-left: none !important;
  border-right: none !important;
  background: rgba(252, 100, 50, 0.4);
  /* margin: 5px 0; */
  border-bottom: none;
  text-align: left;
}
.list-item-stam:last-child {
  background: rgba(252, 100, 50, 0.4);

  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px 0 4px 4px;
  border-bottom: 1px solid #fc6432;
}
