.join-presale-btn {
  transition: transform 0.2s ease;
}

.join-presale-btn:hover {
  transform: scale(1.03);
}

.dab-k .page_tableContainer__p8Vj_ {
  max-width: 90%;
  width: 100%;
  min-height: 10rem;
  height: auto;
  text-align: center;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 30px;
  margin: 0 auto 2rem;
}

.dashboard-snova-heading {
  font-family: "Matter SQ", sans-serif;
  font-weight: bold;
}

.history-snova-heading {
  font-family: "Matter SQ", sans-serif;
  font-weight: bold;
}

.nova-points {
  height: 25px;
  width: 25px;
  margin-right: 7.5px;
  display: inline;
}

.page_tableContainer__p8Vj_ {
  max-width: 1100px;
  width: 100%;
  min-height: 10rem;
  height: auto;
  text-align: center;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 30px;
  margin: 0 auto 2rem;
}

.table-fixed {
  table-layout: fixed;
}

.table-fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-fixed-empty-state {
  table-layout: fixed;
}

/* @media (min-width: 768px) and (max-width: 1100px) {
  .dab-k .page_tableContainer__p8Vj_ table {
    width: 100%;
  }

  .dab-k .page_tableContainer__p8Vj_ table tr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0px;
  }

  .dab-k .page_tableContainer__p8Vj_ table td:last-child {
    padding-left: 20px !important;
  }

  .dab-k .page_tableContainer__p8Vj_ table td {
    border-bottom: none !important;
  }

  .dab-k .page_tableContainer__p8Vj_ .manh {
    margin-top: 75px;
  }
} */

.hkk.naka.group {
  flex-direction: column;
  align-items: center;
}

/* Настраиваем отступы у кнопки */
.hkk.naka.group .manh {
  margin-top: 8px;
}

.small-icon {
  height: 15px;
  width: 15px;
}

@media (min-width: 1100px) {
  .dab-k .page_tableContainer__p8Vj_ table td:last-child {
    padding-left: 10px !important;
  }

  .dab-k .page_tableContainer__p8Vj_ table tr td:nth-child(2) {
    padding-right: 20px !important;
  }

  .dab-k .page_tableContainer__p8Vj_ table tr td:nth-child(3) {
    padding-left: 20px !important;
  }
}

@media (max-width: 767px) {
  .dab-k .page_tableContainer__p8Vj_ table,
  .dab-k .page_tableContainer__p8Vj_ thead,
  .dab-k .page_tableContainer__p8Vj_ tbody,
  .dab-k .page_tableContainer__p8Vj_ th,
  .dab-k .page_tableContainer__p8Vj_ td,
  .dab-k .page_tableContainer__p8Vj_ tr {
    display: block;
  }

  .dab-k .page_tableContainer__p8Vj_ tr {
    margin-bottom: 1rem;
    border-bottom: 2px solid #1a1b23;
  }

  .dab-k .page_tableContainer__p8Vj_ td {
    padding: 10px 20px;
    position: relative;
    text-align: left;
    border: none;
  }

  .dab-k .page_tableContainer__p8Vj_ td::before {
    content: attr(data-label);
    position: absolute;
    left: 20px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
    text-align: left;
    color: #8d8d8d;
  }

  /* Hide the table headers on small devices */
  .dab-k .page_tableContainer__p8Vj_ thead {
    display: none;
  }

  /* Adjust font sizes for better readability */
  .dab-k .page_tableContainer__p8Vj_ td {
    font-size: 14px;
  }

  .dab-k .page_tableContainer__p8Vj_ .details-text {
    transform: translateY(2px);
  }

  .dab-k .page_tableContainer__p8Vj_ .details-icon {
    width: 16px;
    height: 16px;
    transform: translateX(-2px);
  }

  /* Ensure buttons and links fit within the new layout */
  .dab-k .page_tableContainer__p8Vj_ a {
    display: inline-flex;
    align-items: center;
  }

  /* Target the button */
  .button-bna {
    font-size: 16px;
    /* Reduce font size */
    padding: 8px 16px;
    /* Adjust padding for better fit */
    width: 70%;
    /* Optional: Adjust width if necessary */
    margin-left: auto;
    /* Center the button */
    margin-right: auto;
    /* Optional: Adjust other styles as needed */
  }

  .custom-button-font {
    font-size: 12px !important;
  }
}

@media (min-width: 768px) {
  .dab-k .page_tableContainer__p8Vj_ {
    min-width: 700px;
  }
}

.claim-text {
  transform: translateY(1.5px);
}

.details-text {
  color: #fc6432;
  transform: translateY(5px);
}

.details-icon {
  display: inline;
  transform: translateX(-2.5px);
}

.font-bold {
  font-weight: bold;
}

.dab-k .overflow-hidden {
  overflow: hidden;
}

.dab-k ._btn_dcpj6_1 {
  font-size: large;
}

.dab-k .mb-10 {
  margin-bottom: 50px;
}

.dab-k .dgra {
  align-items: center;
}

.dab-k .text-left {
  text-align: left;
}

.dab-k .p-\[32px\] {
  padding: 32px;
}

.dab-k .bg-baseBg {
  --tw-bg-opacity: 1;
}

.dab-k .rounded-\[30px\] {
  border-radius: 30px;
}

.dab-k .flex-col {
  flex-direction: column;
}

.dab-k .w-full {
  width: 100%;
}

.dab-k .flex {
  display: flex;
}

.dab-k .mb-7 {
  margin-bottom: 1.75rem;
}

.dab-k .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dab-k .font-semibold {
  font-weight: 600;
}

.dab-k .text-\[34px\] {
  font-size: 34px;
}

.dab-k .gap-6 {
  gap: 1.5rem;
}

.dab-k .items-stretch {
  align-items: stretch;
}

.dab-k .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.dab-k .grid {
  display: grid;
}

.dab-k .mb-7 {
  margin-bottom: 1.75rem;
}

.dab-k .p-\[20px\] {
  padding: 20px;
}

.dab-k .bg-black {
  --tw-bg-opacity: 1;
  background-color: #0c0c0c;
}

.dab-k .rounded-\[20px\] {
  border-radius: 20px;
}

.dab-k .w-full {
  width: 100%;
}

.dab-k .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dab-k .font-semibold {
  font-weight: 600;
}

.p00 {
  padding-bottom: 0 !important;
}

.dab-k .p-20p {
  padding-inline: 20px !important;
}

.dab-k .text-\[20px\] {
  font-size: 18px;
}

.dab-k .mr-1 {
  margin-right: 0.25rem;
}

.dab-k .leading-\[100\%\] {
  line-height: 100%;
}

.dab-k .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.dab-k .text-\[16px\] {
  font-size: 16px;
}

.dab-k .py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.dab-k .px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.dab-k .bg-\[\#424242\] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 66 66 / var(--tw-bg-opacity));
}

.dab-k .rounded-\[6px\] {
  border-radius: 6px;
}

.dab-k .whitespace-nowrap {
  white-space: nowrap;
}

.dab-k .bg-\[\#1A1B23\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 27 35 / var(--tw-bg-opacity));
}

.dab-k .w-full {
  width: 100%;
}

.dab-k .h-\[1px\] {
  height: 1px;
}

.dab-k .my-\[13px\] {
  margin-top: 13px;
  margin-bottom: 13px;
}

.dab-k .gap-4 {
  gap: 1rem;
}

.dab-k .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.dab-k .mr-auto {
  margin-right: auto;
}

.dab-k .text-\[\#918FA5\] {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, 0.7);
}

.snova-dollar {
  color: #8d8d8d;
}

.referral-earnings {
  color: #fc6432;
}

.dab-k .headingOne {
  font-size: 36px;
}

.dab-k .headingTwo {
  font-size: 29px;
}

.dab-k .font-semibold {
  font-weight: 600;
}

.no-dis {
  cursor: not-allowed !important;
}

.dab-k .gap-2 {
  gap: 0.5rem;
}

.dab-k .text-accent {
  --tw-text-opacity: 1;
  color: #fc6432;
}

.dab-k .font-bold {
  font-weight: 700;
}

.dab-k .text-\[26px\] {
  font-size: 26px;
}

.dab-k .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dab-k .font-medium {
  font-weight: 500;
}

.dab-k .text-\[16px\] {
  font-size: 16px;
}

.dab-k .top-\[2px\] {
  top: 2px;
}

.dab-k .relative {
  position: relative;
}

.dab-k .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.dab-k .text-\[16px\] {
  font-size: 16px;
}

.dab-k .py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.dab-k .px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.dab-k .\!p-\[20px\] {
  padding: 20px !important;
}

.dab-k .bg-\[\#424242\] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 66 66 / 1);
}

.dab-k .\!bg-\[\#242424\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(36 36 36 / 1) !important;
}

.dab-k .rounded-\[6px\] {
  border-radius: 6px;
}

.dab-k .\!rounded-\[20px\] {
  border-radius: 20px !important;
}

.dab-k .scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.dab-k .rotate-\[270deg\],
.scale-0 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.dab-k .rotate-180,
.translate-y-\[-50\%\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.wsop {
  width: 80%;
}

.hkk {
  /* margin-inline: ; */
}

.naka {
  justify-content: end !important;
  text-align: center;
}

.dab-k .translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
}

.dab-k .min-w-max {
  min-width: -moz-max-content;
  min-width: max-content;
}

.dab-k .min-w-\[32px\] {
  min-width: 32px;
}

.dab-k .min-h-\[32px\] {
  min-height: 32px;
}

.dab-k .mt-0 {
  margin-top: 0;
}

.dab-k .z-20 {
  z-index: 20;
}

.dab-k .left-\[100\%\] {
  left: 100%;
}

.dab-k .left-0 {
  left: 0;
}

.dab-k .p-\[20px\] {
  padding: 20px;
}

.dab-k .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.min-h {
  height: 370px !important;
  overflow: auto;
}

.border-boa {
  border-bottom: 1px solid #1a1b23;
}

.dab-k .rounded-\[20px\] {
  border-radius: 20px;
}

.dab-k .w-full {
  width: 100%;
}

.sddaa {
  width: 30px;
}

.for-sb .main-tp {
  bottom: 29px;
  left: 16px;
  width: max-content;
}

.dab-k .text-\[\#8D8D8D\] {
  --tw-text-opacity: 1;
  color: rgb(141 141 141 / var(--tw-text-opacity));
}

.dab-k .leading-\[20px\] {
  line-height: 20px;
}

.dab-k .font-medium {
  font-weight: 500;
}

.dab-k .py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.dab-k .px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.dab-k .bg-\[\#424242\] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 66 66 / var(--tw-bg-opacity));
}

.dab-k .rounded-\[6px\] {
  border-radius: 6px;
}

.dab-k .h-\[1px\] {
  height: 1px;
}

.dab-k .my-\[13px\] {
  margin-top: 13px;
  margin-bottom: 13px;
}

.main-tp div {
  position: absolute;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid rgb(51, 51, 51);
  border-left: 10px solid transparent;
  z-index: 1000;
  transform: rotate(45deg);
  bottom: -5px;
}

.main-tp {
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(51, 51, 51);
  color: #fc6432;
  padding: 8px;
  border-radius: 4px;
  font-size: 15px;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  width: 100%;
}

.manh {
  width: 100%;
  border: none !important;
  background-color: rgb(252, 100, 50);
  color: rgb(255, 255, 255);
  box-shadow: rgb(192, 64, 0) 6px 6px !important;
  opacity: 0.5;
  border-radius: 0 !important;
}

@media (max-width: 767px) {
  .dab-k .md\:scale-\[50\%\],
  .dab-k .md\:translate-y-\[-100\%\] {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (max-width: 400px) {
  .button-bna {
    font-size: 14px;
    /* Reduce font size */
    padding: 8px 16px;
    /* Adjust padding for better fit */
    width: 70%;
    /* Optional: Adjust width if necessary */
    margin-left: auto;
    /* Center the button */
    margin-right: auto;
    /* Optional: Adjust other styles as needed */
  }
}

@media (max-width: 767px) {
  .dab-k .headingOne {
    font-size: 26px;
  }

  .dab-k .headingTwo {
    font-size: 22px;
  }

  .dab-k .md\:translate-y-\[-100\%\] {
    --tw-translate-y: -100%;
  }
}

@media (max-width: 767px) {
  .md\:right-0 {
    right: 0;
  }
}

@media (max-width: 767px) {
  .main-tp {
    left: 55px;
  }

  .hkk.naka {
    width: 100%;
    justify-content: center !important;
    align-items: center;
  }

  .dab-k .md\:left-auto {
    left: auto;
  }

  .logovl {
    width: 26px;
  }

  .inner-ft {
    font-size: 17px;
  }

  .main-hk {
    padding-right: 10px;
    justify-content: start !important;
  }

  .main-hk a {
    width: 116px;
  }
}

@media (max-width: 767px) {
  ._initial_401a9_1._show_401a9_24.mb-676 {
    width: 100%;
  }

  /* .no-mbl {
    display: none;
  } */
  .wsop {
    width: 100%;
  }

  .min-h {
    height: 270px !important;
  }

  .dab-k .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dab-k .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 680px) {
  .max-cont-wid.table-fixed {
    width: 100% !important;
  }

  .table-fixed {
    width: 619px !important;
  }

  .smallera {
    width: 20px;
  }

  .main-hk {
    justify-content: start !important;
  }
}

@media (max-width: 560px) {
  .dab-k .sm\:p-\[20px\] {
    padding: 20px;
  }
}

@media (max-width: 560px) {
  .table-fixed {
    width: 500px !important;
  }

  .max-cont-wid.table-fixed {
    width: 100% !important;
  }

  .dab-k .sm\:left-0 {
    left: 0;
  }

  .dab-k .p-20p {
    padding-right: 0 !important;
  }

  .pkja {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }

  .smam {
    font-size: 14px;
  }

  .main-hk a {
    font-size: 12px !important;
    width: 90px;
  }

  .dab-k .text-\[14px\] {
    font-size: 12px !important;
  }

  .inner-ft {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .dab-k {
    min-height: 1700px;
    height: auto;
  }

  .dashboard-mn .swx {
    padding-top: 0 !important;
  }
}

/* General styles for all browsers */
.custom-scroll-container {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

/* Inner scrollable content */
.scroll-inner {
  max-height: 370px;
  overflow-y: auto;
  padding-right: 12px;
}

/* WebKit Browsers: Chrome, Safari, Edge */
.scroll-inner::-webkit-scrollbar {
  width: 12px;
  /* Set the width of the scrollbar */
}

.scroll-inner::-webkit-scrollbar-track {
  background: #1e1e1e;
  /* Scrollbar track color */
  border-radius: 20px;
}

.scroll-inner::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 12px;
  /* Rounded thumb */
  margin-top: 15px;
  /* Shorten scrollbar thumb */
  margin-bottom: 15px;
  /* Shorten scrollbar thumb */
}

.scroll-inner::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker thumb on hover */
}

/* Firefox Styles */
.scroll-inner {
  scrollbar-width: thin;
  /* Use thin scrollbar */
  scrollbar-color: #888 #1e1e1e;
  /* Thumb and track color */
}

/* Targeting Firefox specifically */
@-moz-document url-prefix() {
  .scroll-inner {
    padding-right: 8px;
    /* Adjust padding to match the thumb */
  }
}

/* Legacy Edge (Optional, if you need support) */
@supports (-ms-ime-align: auto) {
  .scroll-inner {
    scrollbar-face-color: #888;
    /* Thumb color */
    scrollbar-highlight-color: #555;
    /* Thumb highlight color */
    scrollbar-shadow-color: #333;
    /* Track shadow */
  }
}
