.orange-text {
  color: #fc6432;
  gap: 10px;
}

.white-text {
  color: #fff;
  gap: 10px;
}

.docs-btn {
  background: rgb(28, 28, 28);
  color: #fff;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0;
  align-items: center;
  width: auto;
  display: flex;
  position: relative;
  transform: scale(1.03);
  transition: transform 0.2s ease;
}

.mbeen {
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.15),
    rgba(252, 100, 50, 0.6),
    rgba(255, 255, 255, 0.15)
  );
  border-image-slice: 1;
  padding-bottom: 15px;

  border-bottom: 2px solid transparent;
}

.box-btn-nova-guide.corner-box::before {
  width: calc(102% - 1.3rem);
  background-color: rgb(28, 28, 28);
}

.box-btn-nova-guide.corner-box::after {
  height: calc(110% - 1.3rem);
  background-color: rgb(28, 28, 28);
}
.box-btn-nova-guide {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 10px; */
  height: 50px;
  min-width: 267px;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  border: 1px solid #fc6432 !important;
  /* background-color: #151515; */
  /* background-color: #3a161e; */
  background-color: rgb(28, 28, 28);
  transition: all 0.3s ease;
}
