.bottomContainer .questionWeHaveContainer {
  justify-content: space-between;
  display: flex;
}
.bottomContainer .faqTag {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fc6432;
}
.bottomContainer .faqHEading {
  margin: 2rem 0;
  font-style: normal;

  font-family: "Matter SQ", sans-serif !important;
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: white;
  /* width: 400px; */
}
.bottomContainer .questionWeHaveContainer hr {
  /* opacity: 0; */
  border-color: #fc6432;
  color: #fc6432;
}
.for-mbl {
  display: none;
}
.bottomContainer .contactUsFaq {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  /* color: white; */
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.bottomContainer .faqBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 40px;
  border: 1.25px solid #fc6432;
  border-radius: 90px;
  margin: 20px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: white;
}
.bottomContainer p {
  animation: fadeIn 1s ease-in-out;
}
.questionsRight {
  /* max-width: 690px; */
  width: 60%;
}
.flat-accordion .flat-toggle.corner-box {
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  background-color: #151515;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 20px 18px 5px;
}

.flat-accordion .flat-toggle {
  margin-bottom: 24px;
}
.countdown__item,
.corner-box {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 2px solid #fc6432;
  padding: 18px 20px;
  position: relative;
  z-index: 1;
}
.box-text {
  position: relative;
  /* background: rgba(34, 183, 143, 0.1); */
  transform: translateY(0);
}
.box-text.active,
.box-text:hover {
  box-shadow: 0 0px 32px 0 rgba(252, 100, 50, 0.5);
}
.countdown__item.active,
.countdown__item:hover,
.corner-box.active,
.corner-box:hover {
  border-color: #fc6432;
}
.countdown__item.active::before,
.countdown__item:hover::before,
.corner-box.active::before,
.corner-box:hover::before {
  width: 0 !important;
}

.countdown__item::before,
.corner-box::before {
  width: calc(102% - 3rem);
  height: calc(100% + 4px);
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
}
.countdown__item::before,
.countdown__item::after,
.corner-box::before,
.corner-box::after {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: "";
  position: absolute;
  /* background: #151515; */
  z-index: -1;
}
.flat-accordion .flat-toggle.corner-box .toggle-title:hover {
  cursor: context-menu;
}
.flat-accordion .flat-toggle.corner-box img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  right: 4px;
  top: 2px;
  font-weight: 500;
  font-size: 25px;
  width: 25px;
  color: #fc6432;
}
.countdown__item.active::after,
.countdown__item:hover::after,
.corner-box.active::after,
.corner-box:hover::after {
  height: 0 !important;
}

.countdown__item::after,
.corner-box::after {
  height: calc(110% - 3rem);
  width: calc(100% + 4px);
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
}
.countdown__item::before,
.countdown__item::after,
.corner-box::before,
.corner-box::after {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: "";
  position: absolute;
  /* background: #151515; */
  background: #1a1a1a;
  z-index: -1;
}
.questionsLeft {
  width: 30%;
}
.toggle-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); /* Smoother transition */

  display: none; /* Initially hide the content */
}

.active .toggle-content {
  transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); /* Smoother transition */

  max-height: 1000px; /* Adjust to a value greater than the maximum height your content might have */
  display: block; /* Show the content when open */
}

/* .flat-accordion .flat-toggle .toggle-content {
  display: none;
} */
.flat-accordion .flat-toggle.active.corner-box {
  padding: 24px 18px;
}
.flat-accordion .flat-toggle.active.corner-box .toggle-title {
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(252, 100, 50, 0.1);
}
.flat-accordion .flat-toggle.active.corner-box img {
  transform: rotate(-180deg);
}
.box-text p {
  color:rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}
.flat-accordion .flat-toggle.corner-box .toggle-title {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-family: "Matter SQ", sans-serif;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(3, 253, 203, 0);
  padding-bottom: 16px;
  margin-bottom: 0;
  position: relative;
  padding-right: 30px;
}
.box-text .h7 {
  /* text-transform: capitalize; */
  margin-bottom: 16px;
}
.h7 {
  font-size: 24px;
  cursor: pointer;
  line-height: 1.33;
}
.h7,
.h8 {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  color: #fff;
}
button {
  font-family: "Matter Mono", sans-serif !important;
}
@media screen and (max-width: 767px) {
  .faqHEading {
    width: auto;
    font-size: 32px;
  }

  .contactUsFaq {
    justify-content: space-evenly;
    align-items: center;
  }
  .questionsLeft {
    text-align: center;
  }
  .bottomContainer .questionWeHaveContainer {
    flex-direction: column;
  }
  .questionsLeft {
    width: 100%;
  }
  .questionsRight {
    width: 100%;
  }
  .bottomContainer .contactUsFaq {
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
  .bottomContainer .faqHEading {
    width: auto;
    font-size: 32px;
  }
}
@media (max-width: 600px) {
  .for-mbl {
    display: block;
  }
  .bottomContainer.section-padding {
    padding-top: 60px !important;
    padding-bottom: 70px !important;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
